import React, { useEffect, useState } from 'react'
import alasql from "alasql";
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormSelect from 'react-bootstrap/FormSelect';
import Label from 'react-bootstrap/FormLabel';
import { DateRangePicker } from "rsuite";
import { Sankey, Tooltip } from 'recharts';
import { SankeyNodeComponent, SankeyLinkComponent, SankeyCustomTooltip } from "../components/SankeyComponent"



const color_combo_for_link_and_nodes = [
    ["#daab00", "#fbf6e9"],
    ["#ef9e22", "#fdf5eb"],
    ["#2fafcc", "#ecf7f9"],
    ["#de20aa", "#fbebf6"],
    ["#d124fa", "#faecff"],
];


function Logs({ViewedPage, shop_domain, app_url}) {
    ViewedPage(3)

    const [journey_by_color_mapping_dict, setJourneyByColorMapping] = useState({})
    const [loading, setLoading] = useState(true);
    const [journey_by_value, setProductJourneyByValue] = useState("product_title");
    const [nodes_link_mapping_arr, setNodeLinkMapping] = useState([]);
    const [selected_date_range_value, setDateRange] = useState([]);
    const [raw_order_data, setOrderData] = useState([]);
    const [sankey_data, setSankeyData] = useState([]);

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 180));
                setDateRange([created_at_min, created_at_max]);
                axios.post(app_url+"/api/shopify/get_shopify_customer_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    // console.log(data)
                    setOrderData(data.data.order_data);
                    var { formated_data, temp_journey_color_mapping, nodes_link_mapping } = format_for_sankey_data(data.data.order_data, "product_title");
                    setNodeLinkMapping(nodes_link_mapping)
                    setJourneyByColorMapping(temp_journey_color_mapping)
                    setSankeyData(formated_data);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }, [app_url, shop_domain]);

    const handleDateChange = async (date_range) => {
        if((date_range !== undefined) && (date_range !== null) && (date_range.length > 0)){
            setDateRange(date_range);
            var created_at_min = date_range[0];
            var created_at_max = date_range[1];
            setLoading(true)
            try {
                if(Cookies.get('shop')){
                    axios.post(app_url+"/api/shopify/get_shopify_customer_data",{
                        shop_domain:Cookies.get('shop'),
                        created_at_min:formatDate(created_at_min),
                        created_at_max:formatDate(created_at_max)
                    }, {withCredentials:true})
                    .then(request => request.data)
                    .then(data => {
                        // console.log(data)
                        setOrderData(data.data.order_data);
                    var { formated_data, temp_journey_color_mapping, nodes_link_mapping } = format_for_sankey_data(data.data.order_data, journey_by_value);
                    setNodeLinkMapping(nodes_link_mapping)
                    setJourneyByColorMapping(temp_journey_color_mapping)
                    setSankeyData(formated_data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        toast.error("No Data to Show.");
                        console.log(err)
                    });
                }
            }
            catch(err){
                toast.error("No Data to Show.");
                console.log(err)
            }
        }
    };

    const handleFilter = (e) => {
        e.preventDefault();
        var filter_value = document.getElementById("journey_by").value; 
        setProductJourneyByValue(filter_value)
        setLoading(true)
        setTimeout(()=> {
            try{
                var { formated_data, temp_journey_color_mapping, nodes_link_mapping } = format_for_sankey_data(raw_order_data, filter_value);
                setNodeLinkMapping(nodes_link_mapping)
                setJourneyByColorMapping(temp_journey_color_mapping)
                setSankeyData(formated_data);
                if((formated_data?.nodes === undefined) || (formated_data?.links === undefined) || (formated_data["nodes"].length === 0) || (formated_data["links"].length === 0)){
                    console.log("No data")
                    toast.error("No data to show");
                }
                setLoading(false);
            }
            catch(err){
                setLoading(false)
                toast.error("No Data to Show.");
                console.log(err)
            }
        }, 500)
    }

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h6 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h6>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Product Journey</h2>
                            </div>

                            <Container className='mt-3'>
                                <Row className='justify-content-md-left' lg={4} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker defaultValue={selected_date_range_value} id='date_range' format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Product journey by</Label>
                                        <br />
                                        <FormSelect id='journey_by' defaultValue={journey_by_value} onChange={handleFilter}>
                                            <option key="product_title" value="product_title">Product</option>
                                            <option key="product_type" value="product_type">Product Type</option>
                                            <option key="sku" value="sku">SKU</option>
                                        </FormSelect>    
                                    </Col>
                                </Row>
                            </Container>
                            
                            <div className='d-flex align-items-center justify-content-center mx-2 mt-3' style={{ backgroundColor:"white", borderRadius:"10px" }}>
                                {((sankey_data?.nodes !== undefined) && (sankey_data?.links !== undefined) && (sankey_data["nodes"].length > 0) && (sankey_data["links"].length > 0)) &&
                                    <Sankey
                                        width={1200}
                                        height={800}
                                        data={sankey_data}
                                        node={<SankeyNodeComponent journey_by_color_mapping_dict={journey_by_color_mapping_dict} />}
                                        nodePadding={50}
                                        margin={{
                                            left: 100,
                                            right: 100,
                                            top: 50,
                                            bottom: 50,
                                        }}
                                        link={<SankeyLinkComponent journey_by_color_mapping_dict={journey_by_color_mapping_dict}/>}
                                    >
                                        <Tooltip
                                            content={<SankeyCustomTooltip order_data={raw_order_data} node_link_mapping={nodes_link_mapping_arr} journey_by_value={journey_by_value}/>}
                                            animationDuration={'500'}
                                            animationEasing='ease-in-out'
                                        />
                                    </Sankey>
                                }
                            </div>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default Logs


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}

function format_for_sankey_data(order_data, prod_journey_by_value){
    let res = alasql(`SELECT customer_id, created_at, STRING_SPLIT(${prod_journey_by_value}, "<br />") as ${prod_journey_by_value} FROM ? ORDER BY customer_id, created_at`,[order_data]);
    res = alasql(`SELECT customer_id, ARRAY(${prod_journey_by_value}) as from_to_array FROM ? GROUP BY customer_id`,[res]);
    
    let nodes_arr = [];
    let nodes_link_mapping = [];
    let temp_journey_color_mapping = {};
    let links_arr = [];
    var max_len = 0;
    for(let iter of res){
        max_len = max_len < iter["from_to_array"].length ? iter["from_to_array"].length : max_len;
    }
    
    var top_product = [];
    for(let i=0; (i < max_len) && (i < 4); i++){
        let ith_order_top_product_dict = {};
        for(let iter of res){
            if((iter["from_to_array"].length > 1) && (iter["from_to_array"].length > i)){
                for(let val of iter["from_to_array"][i]){
                    if(Object.keys(ith_order_top_product_dict).includes(val)){
                        ith_order_top_product_dict[val] = ith_order_top_product_dict[val] + 1;
                    }
                    else{
                        ith_order_top_product_dict[val] = 1;
                    }
                }
            }
        }

        const entries = Object.entries(ith_order_top_product_dict);
        const sortedEntries = entries.sort(([, value1], [, value2]) => value2 - value1);
        const topEntries = sortedEntries.slice(0, 9);
        ith_order_top_product_dict = Object.fromEntries(topEntries);
        top_product.push(ith_order_top_product_dict);
    }
    

    // For Nodes
    let x = 0;
    for(let i=0; (i < max_len) && (i < 4); i++){
        // for(let i=0; (i < max_len); i++){
        nodes_link_mapping.push({});
        nodes_arr.push([]);
        for(let iter of res){
            if((iter["from_to_array"].length > 1) && (iter["from_to_array"].length > i)){
                for(let val of Array.from(new Set(iter["from_to_array"][i]))){
                    if((nodes_arr[i].filter((value) => value.name === val)).length === 0){
                        if(Object.keys(top_product[i]).includes(val)){
                            nodes_arr[i].push({"name":val});
                            if(!Object.keys(temp_journey_color_mapping).includes(val)){
                                temp_journey_color_mapping[val] = color_combo_for_link_and_nodes[x%5];
                                x++;
                            }
                            nodes_link_mapping[i][val] = Object.keys(nodes_link_mapping[i]).includes(val)? nodes_link_mapping[i][val]: nodes_arr.flat().length - 1;
                        }
                    }
                }
            }
        }
    }

    nodes_arr = nodes_arr.flat();
    
    // For Links
    for(let i=0; (i < max_len) && (i < 4); i++){
    // for(let i=1; (i < max_len) ; i++){
        let temp_sankey_charts_arr = []
        for(let iter of res){
            if((iter["from_to_array"].length> 1) && (iter["from_to_array"].length > i)){
                for(let first_arr_val of Array.from(new Set(iter["from_to_array"][i-1]))){
                    if(Object.keys(top_product[i-1]).includes(first_arr_val)){
                        for(let last_arr_val of Array.from(new Set(iter["from_to_array"][i]))){
                            if(Object.keys(top_product[i]).includes(last_arr_val)){
                                temp_sankey_charts_arr.push({
                                    "source":nodes_link_mapping[i-1][first_arr_val],
                                    "target":nodes_link_mapping[i][last_arr_val],
                                    "value":top_product[i-1][first_arr_val]
                                });
                            }
                        }
                    }
                }
            }
        }
        if(temp_sankey_charts_arr.length > 0){
            temp_sankey_charts_arr = alasql(`SELECT [source], [target], FIRST([value]) as [value] FROM ? GROUP BY [source],[target]`,[temp_sankey_charts_arr]);
            links_arr = links_arr.concat(temp_sankey_charts_arr);
        }
    }
    
    // links_arr = alasql(`SELECT * FROM ? WHERE [value] > 5`,[links_arr]);
    let final_sankey_charts_arr = {
        "nodes":nodes_arr,
        "links":links_arr
    };

    // console.log("----------------------------------------");
    // console.log(prod_journey_by_value);
    // console.log(res);
    // console.log(final_sankey_charts_arr);
    // console.log("----------------------------------------");

    return {
        formated_data:final_sankey_charts_arr,
        nodes_link_mapping:nodes_link_mapping,
        temp_journey_color_mapping:temp_journey_color_mapping
    };
}