import React, {useState} from 'react'
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import axios from 'axios';

function GoogleAdsCopySS({ViewedPage, shop_domain, app_url}) {
    ViewedPage(15);

    const [loading, setLoading] = useState(false);

    const handleGenerateAdCopies = (e) => {
      e.preventDefault();
      try {
        if(Cookies.get('shop')){
            axios.post(app_url+"/api/shopify/generate_ads_copy_ss",{
              client_name:"hey_jane",
            }, {withCredentials:true})
            .then(request => request.data)
            .then(data => {
                setLoading(false);
                toast.success("Ad Copies Created.");
            })
            .catch(err => {
                setLoading(false);
                toast.error("No Data to Show.");
                console.log(err)
            });
        }
    }
    catch(err){
        toast.error("No Data to Show.");
        console.log(err)
    }
    }

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title my-4'>
                                <h2>New Adgroups / Search Ad Copies Generation</h2>
                            </div>

                            <Button variant='primary' onClick={handleGenerateAdCopies} >Generate Ad Copies</Button>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default GoogleAdsCopySS