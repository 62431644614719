import React, { useEffect, useState } from 'react'
import CohortCharts from "../components/CustomCohortCharts";
import { DateRangePicker } from "rsuite";
import alasql from "alasql";
import axios from 'axios';
import Cookies from 'js-cookie';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormSelect from 'react-bootstrap/FormSelect';
import Label from 'react-bootstrap/FormLabel';

alasql.fn.GET_MONTH_FOR_SORT = function(date_str){
    var months = {'Jan':'01', 'Feb':'02', 'Mar':'03', 'Apr':'04', 'May':'05', 'Jun':'06', 'Jul':'07', 'Aug':'08', 'Sep':'09', 'Oct':'10', 'Nov':'11', 'Dec':'12'};
    return date_str.split(" ")[1]+"-"+months[date_str.split(" ")[0]];
}

alasql.fn.get_months = function(date_str){
    var date = new Date(date_str.split("T")[0]);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[date.getMonth()] + " " + date.getFullYear();
}
alasql.fn.get_months_since_first_order = function(created_at, customer_joining_date){
    let months;
    let d2 = new Date(created_at.split("T")[0]);
    let d1 = new Date(customer_joining_date.split("T")[0]);
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}
alasql.fn.ConvertArrayToFilterLabel = function(arr){
    var temp_arr = [];
    if(arr){
        for(var x of arr){
            if(x !== ""){
                temp_arr.push({"value":x,"label":x})
            }
        }
    }
    return temp_arr;
}
alasql.fn.FLOAT = function(num_str){
    return parseFloat(num_str);
}
alasql.fn.STRING_SPLIT = function(val,split_with){
    return val.split(split_with);
}
alasql.fn.GET_FIRST_ELEMENT = function(arr){
    return arr[0];
}
alasql.fn.get_ids_from_string = function(obj,replace_value){
    return parseInt(obj.replace(replace_value, ""));
}

function Cohort({ViewedPage, shop_domain, app_url}) {
    ViewedPage(2)

    const [loading, setLoading] = useState(true);
    const [show_filters, setShowfilters] = useState(false);
    const [filter_unique_value_dict, setFilterUniqueValueDict] = useState({
        "last_visit_campaign":[],
        "last_visit_content":[],
        "last_visit_medium":[],
        "last_visit_source":[],
        "last_visit_term":[]
    });
    const [selected_filter_unique_value_dict, setSelectedFilterUniqueValueDict] = useState({
        "last_visit_campaign":{"operator":"IN","filter_val":[],"order_no":"any"},
        "last_visit_content":{"operator":"IN","filter_val":[],"order_no":"any"},
        "last_visit_medium":{"operator":"IN","filter_val":[],"order_no":"any"},
        "last_visit_source":{"operator":"IN","filter_val":[],"order_no":"any"},
        "last_visit_term":{"operator":"IN","filter_val":[],"order_no":"any"}
    });
    const [compare_cohort_value, setCompareCohortValue] = useState("customer_joining_date");
    const [currency_code, setCurrencyCode] = useState("");
    const [selected_metrics, setMetrics] = useState("net_sales_per_customer");
    const [selected_date_range_value, setDateRange] = useState([]);
    const [raw_order_data, setOrderData] = useState([]);
    const [cohort_chart_data, setCohortData] = useState({});

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 180));
                axios.post(app_url+"/api/shopify/get_shopify_customer_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    console.log(data)
                    setOrderData(data.data.order_data);
                    setDateRange([created_at_min, created_at_max])
                    setCurrencyCode(data.data.order_data.length > 0 ? data.data.order_data[0]["currency_code"]: "");
                    setFilterUniqueValueDict(get_filter_unique_value(data.data.order_data));
                    var formated_data = get_accumulated_data_per_customer(data.data.order_data, {"last_visit_campaign":{"operator":"IN","filter_val":[],"order_no":"any"},"last_visit_content":{"operator":"IN","filter_val":[],"order_no":"any"},"last_visit_medium":{"operator":"IN","filter_val":[],"order_no":"any"},"last_visit_source":{"operator":"IN","filter_val":[],"order_no":"any"},"last_visit_term":{"operator":"IN","filter_val":[],"order_no":"any"}}, formatDate(created_at_min), formatDate(created_at_max), "current_total_price");
                    setCohortData(formated_data);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }, [app_url, shop_domain]);


    const handleDateChange = async (date_range) => {
        if((date_range !== undefined) && (date_range !== null) && (date_range.length > 0)){
            setDateRange(date_range);
            var created_at_min = date_range[0];
            var created_at_max = date_range[1].setDate(date_range[1].getDate() + 1);
            var compare_filter_value = document.getElementById("compare").value; 
            var metrics_filter_value = document.getElementById("metrics").value; 
            setLoading(true)
            try {
                if(Cookies.get('shop')){
                    axios.post(app_url+"/api/shopify/get_shopify_customer_data",{
                        shop_domain:Cookies.get('shop'),
                        created_at_min:formatDate(created_at_min),
                        created_at_max:formatDate(created_at_max)
                    }, {withCredentials:true})
                    .then(request => request.data)
                    .then(data => {
                        console.log(data)
                        setOrderData(data.data.order_data);
                        setFilterUniqueValueDict(get_filter_unique_value(data.data.order_data));
                        var formated_data = [];    
                        setCurrencyCode(data.data.order_data.length > 0 ? data.data.order_data[0]["currency_code"]: "");
                        if(metrics_filter_value === "sales_per_customer"){
                            formated_data = get_accumulated_data_per_customer(data.data.order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), "total_price", compare_filter_value);
                        }
                        else if(metrics_filter_value === "net_sales_per_customer"){
                            formated_data = get_accumulated_data_per_customer(data.data.order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), "current_total_price", compare_filter_value);
                        }
                        else if(metrics_filter_value === "profit_per_customer"){
                            formated_data = get_accumulated_data_per_customer(data.data.order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), "profit", compare_filter_value);
                        }
                        else if(metrics_filter_value === "average_order_value"){
                            formated_data = get_average_order_value_data(data.data.order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), compare_filter_value);
                        }
                        else if(metrics_filter_value === "customer_who_purchased_x_times"){
                            formated_data = get_customer_purchased_x_times_data(data.data.order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), compare_filter_value);
                        }
                        setCohortData(formated_data); 
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        toast.error("No Data to Show.");
                        console.log(err)
                    });
                }
            }
            catch(err){
                toast.error("No Data to Show.");
                console.log(err)
            }
        }
    };
    
    const handleMetricAndCompareChange = (e) => {
        e.preventDefault();
        var metrics_filter_value = document.getElementById("metrics").value; 
        setMetrics(metrics_filter_value);
        var compare_filter_value = document.getElementById("compare").value; 
        setCompareCohortValue(compare_filter_value)
        setLoading(true)
        setTimeout(()=> {
            try{
                var created_at_min = selected_date_range_value[0];
                var created_at_max = selected_date_range_value[1].setDate(selected_date_range_value[1].getDate() + 1);
                var formated_data = [];
                if(metrics_filter_value === "sales_per_customer"){
                    formated_data = get_accumulated_data_per_customer(raw_order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), "total_price", compare_filter_value);
                }
                else if(metrics_filter_value === "net_sales_per_customer"){
                    formated_data = get_accumulated_data_per_customer(raw_order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), "current_total_price", compare_filter_value);
                }
                else if(metrics_filter_value === "profit_per_customer"){
                    formated_data = get_accumulated_data_per_customer(raw_order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), "profit", compare_filter_value);
                }
                else if(metrics_filter_value === "average_order_value"){
                    formated_data = get_average_order_value_data(raw_order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), compare_filter_value);
                }
                else if(metrics_filter_value === "customer_who_purchased_x_times"){
                    formated_data = get_customer_purchased_x_times_data(raw_order_data, selected_filter_unique_value_dict, formatDate(created_at_min), formatDate(created_at_max), compare_filter_value);
                }
                setCohortData(formated_data);
                setLoading(false)
            }
            catch(err){
                setLoading(false)
                toast.error("No Data to Show.");
                console.log(err)
            }
        }, 500)
    }

    const handle_filters_hide = () => setShowfilters(false);
    const handle_filters_show = () => setShowfilters(true);
    const handle_filters_submit = (e) => {
        e.preventDefault();
        handle_filters_hide();
        handleMetricAndCompareChange(e);
    };


    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h6 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h6>
                </div>
                :
                <>
                    <Modal show={show_filters} onHide={handle_filters_hide}>
                        <Modal.Header closeButton>
                        <Modal.Title>Filters</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{height:"60vh", overflowY:"scroll"}}>
                            
                            {
                                ["source","medium","campaign","term","content"].map((val1) => {
                                    return ["last"].map((val2) => {
                                        return (                            
                                        <Container>
                                            <Row className='my-3'>
                                                <p className='charts_popup_filter_heading'>UTM {val1}</p>
                                            </Row>
                                            <Row lg={2} xs={2}>
                                                <ReMultiSelect 
                                                    className='popup_filter_include_exclude'
                                                    closeMenuOnSelect={false}
                                                    id={`${val2}_visit_${val1}_include_exclude`} 
                                                    components={ReMultiSelectAnimated()}
                                                    defaultValue={[{ value: selected_filter_unique_value_dict[`${val2}_visit_${val1}`]["operator"] === "NOT IN"? "exclude":"include", label: selected_filter_unique_value_dict[`${val2}_visit_${val1}`]["operator"] === "NOT IN"? "Exclude":"Include" }]}
                                                    options={[
                                                        { value: 'include', label: 'Include' },
                                                        { value: 'exclude', label: 'Exclude' },
                                                    ]}
                                                    onChange={(selected_vals) => {
                                                        let temp_filter_dict = { ...selected_filter_unique_value_dict };
                                                        temp_filter_dict[`${val2}_visit_${val1}`]["operator"] = selected_vals["value"] === "exclude"? "NOT IN": "IN";
                                                        setSelectedFilterUniqueValueDict(temp_filter_dict);
                                                    }}
                                                />
                                                <ReMultiSelect 
                                                    className='popup_filter_orders_option'
                                                    closeMenuOnSelect={false}
                                                    id={`${val2}_visit_${val1}_orders_option`}
                                                    components={ReMultiSelectAnimated()}
                                                    defaultValue={[{ value: selected_filter_unique_value_dict[`${val2}_visit_${val1}`]["order_no"], label: selected_filter_unique_value_dict[`${val2}_visit_${val1}`]["order_no"][0].toUpperCase() +
                                                    selected_filter_unique_value_dict[`${val2}_visit_${val1}`]["order_no"].slice(1) + " order"}]}
                                                    options={[
                                                        { value: 'first', label: 'First order' },
                                                        { value: 'any', label: 'Any order' },
                                                        { value: 'all', label: 'All order' }
                                                    ]}
                                                    onChange={(selected_vals) => {
                                                        let temp_filter_dict = { ...selected_filter_unique_value_dict };
                                                        temp_filter_dict[`${val2}_visit_${val1}`]["order_no"] = selected_vals["value"];
                                                        setSelectedFilterUniqueValueDict(temp_filter_dict);
                                                    }}
                                                    />
                                            </Row>
                                            <Row>
                                                <ReMultiSelect
                                                    className='popup_filter_options'
                                                    id={`${val2}_visit_${val1}_options`}
                                                    closeMenuOnSelect={false}
                                                    components={ReMultiSelectAnimated()}
                                                    defaultValue={selected_filter_unique_value_dict[`${val2}_visit_${val1}`]["filter_val"].map((x) => {
                                                        return {"value":x,"label":x}
                                                    })}
                                                    isMulti
                                                    options={filter_unique_value_dict[`${val2}_visit_${val1}`]}
                                                    onChange={(selected_vals) => {
                                                        let temp_filter_dict = { ...selected_filter_unique_value_dict };
                                                        temp_filter_dict[`${val2}_visit_${val1}`]["filter_val"] = selected_vals.map((x) => { return x["value"]});
                                                        setSelectedFilterUniqueValueDict(temp_filter_dict);
                                                    }}
                                                />
                                            </Row>
                                        </Container>)
                                    })
                                })
                            }

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handle_filters_hide}>Close</Button>
                            <Button variant="primary" onClick={handle_filters_submit}>Submit</Button>
                        </Modal.Footer>
                    </Modal>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Lifetime Value Report</h2>
                            </div>


                            <Container className='mt-3 mb-2'>
                                <Row className='justify-content-md-left' lg={4} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker id='date_range' defaultValue={selected_date_range_value} format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Metric(s)</Label>
                                        <br />
                                        <FormSelect id='metrics' defaultValue={selected_metrics} onChange={handleMetricAndCompareChange}>
                                            <option key="profit_per_customer" value="profit_per_customer">Accumulated gross profit per customer</option>
                                            <option key="net_sales_per_customer" value="net_sales_per_customer">Accumulated net sales per customer</option>
                                            <option key="sales_per_customer" value="sales_per_customer">Accumulated sales per customer</option>
                                            <option key="average_order_value" value="average_order_value">Average order value</option>
                                            <option key="customer_who_purchased_x_times" value="customer_who_purchased_x_times">Customer who purchased x times</option>
                                        </FormSelect>    
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Compare</Label>
                                        <br />
                                        <FormSelect id='compare' defaultValue={compare_cohort_value} onChange={handleMetricAndCompareChange}>
                                            <option key="customer_joining_date" value="customer_joining_date">None</option>
                                            <option key="product_title" value="product_title">First Order Product</option>
                                            <option key="product_type" value="product_type">First Order Product Type</option>
                                            <option key="sku" value="sku">First Order SKU</option>
                                            <option key="customer_tags" value="customer_tags">Customer Tag</option>
                                            <option key="tags" value="tags">First Order Order Tag</option>
                                            <option key="discount_codes" value="discount_codes">First Order Discount Code</option>
                                            <option key="country" value="country">Country</option>
                                            <option key="first_visit_source" value="first_visit_source">Source (First Visit)</option>
                                            <option key="first_visit_medium" value="first_visit_medium">Medium (First Visit)</option>
                                        </FormSelect>    
                                    </Col>
                                    <Col className='p-2 d-flex align-items-end'>
                                        <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={handle_filters_show}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
                                            <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                                        </svg>
                                        Filters
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>

                            <Container className='p-2 mt-3'>
                                <CohortCharts
                                    cohort_by={selected_metrics === "customer_who_purchased_x_times"? "Orders": "Months since first order"}
                                    currency_code={selected_metrics !== "customer_who_purchased_x_times"? currency_code: ""}
                                    data={cohort_chart_data}
                                />
                            </Container>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default Cohort


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}


function get_filter_unique_value(order_data){    
    var temp_filter_data = {};
    temp_filter_data["first_visit_channel"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT first_visit_channel)) as first_visit_channel FROM ?`,[order_data])[0]["first_visit_channel"];
    temp_filter_data["first_visit_campaign"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT first_visit_campaign)) as first_visit_campaign FROM ?`,[order_data])[0]["first_visit_campaign"];
    temp_filter_data["first_visit_content"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT first_visit_content)) as first_visit_content FROM ?`,[order_data])[0]["first_visit_content"];
    temp_filter_data["first_visit_medium"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT first_visit_medium)) as first_visit_medium FROM ?`,[order_data])[0]["first_visit_medium"];
    temp_filter_data["first_visit_source"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT first_visit_source)) as first_visit_source FROM ?`,[order_data])[0]["first_visit_source"];
    temp_filter_data["first_visit_term"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT first_visit_term)) as first_visit_term FROM ?`,[order_data])[0]["first_visit_term"];
    temp_filter_data["last_visit_channel"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT last_visit_channel)) as last_visit_channel FROM ?`,[order_data])[0]["last_visit_channel"];
    temp_filter_data["last_visit_campaign"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT last_visit_campaign)) as last_visit_campaign FROM ?`,[order_data])[0]["last_visit_campaign"];
    temp_filter_data["last_visit_content"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT last_visit_content)) as last_visit_content FROM ?`,[order_data])[0]["last_visit_content"];
    temp_filter_data["last_visit_medium"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT last_visit_medium)) as last_visit_medium FROM ?`,[order_data])[0]["last_visit_medium"];
    temp_filter_data["last_visit_source"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT last_visit_source)) as last_visit_source FROM ?`,[order_data])[0]["last_visit_source"];
    temp_filter_data["last_visit_term"] = alasql(`SELECT ConvertArrayToFilterLabel(ARRAY(DISTINCT last_visit_term)) as last_visit_term FROM ?`,[order_data])[0]["last_visit_term"];

    return temp_filter_data;
}


function apply_filter_on_data(data, selected_filter){
    let filtered_data = data;
    for(let key of Object.keys(selected_filter)){
        if(selected_filter[key]["filter_val"].length > 0){
            if(selected_filter[key]["order_no"] === "any"){
                filtered_data = alasql(`SELECT * FROM ? WHERE ${key+" "+selected_filter[key]["operator"]+" ('"+selected_filter[key]["filter_val"].join("','")+"')"}`,[filtered_data]);
            }
            if(selected_filter[key]["order_no"] === "first"){
                let temp_filtered_data = alasql(`SELECT * FROM ? GROUP BY customer_id ORDER BY created_at`,[filtered_data]);
                temp_filtered_data = alasql(`SELECT * FROM ? WHERE ${key+" "+selected_filter[key]["operator"]+" ('"+selected_filter[key]["filter_val"].join("','")+"')"}`,[temp_filtered_data]);
                let filtered_customer_id_arr = alasql(`SELECT ARRAY(customer_id) FROM ?`,[temp_filtered_data]);
                filtered_data = alasql(`SELECT * FROM ? WHERE customer_id IN (${filtered_customer_id_arr[0]["ARRAY(customer_id)"].join(",")})`,[filtered_data]);
            }
            if(selected_filter[key]["order_no"] === "all"){
                let customer_order_count_data = alasql(`SELECT customer_id, COUNT(id) as customer_total_order FROM ? GROUP BY customer_id`,[filtered_data]);
                let joined_data = alasql(` SELECT * FROM ? AS filtered_data JOIN ? AS customer_order_count_data ON filtered_data.customer_id = customer_order_count_data.customer_id`,[filtered_data, customer_order_count_data])
                let temp_filtered_data = alasql(`SELECT * FROM ? WHERE ${key+" "+selected_filter[key]["operator"]+" ('"+selected_filter[key]["filter_val"].join("','")+"')"}`,[joined_data]);
                let filtered_customer_id_arr = alasql(`SELECT ARRAY(customer_id) FROM ?`,[temp_filtered_data]);
                filtered_data = alasql(`SELECT * FROM ? WHERE customer_id IN (${filtered_customer_id_arr[0]["ARRAY(customer_id)"].join(",")})`,[filtered_data]);
            }
        }
    }
    return filtered_data;

}


function get_accumulated_data_per_customer(order_data, selected_filter, created_at_min, created_at_max, accumulated_by, compare="customer_joining_date"){    

    let filtered_data = apply_filter_on_data(order_data, selected_filter)

    var res = alasql(`SELECT customer_id, get_months_since_first_order(created_at, customer_joining_date) as months_since_order, created_at, CAST(${accumulated_by} AS FLOAT) as ${accumulated_by}, ${accumulated_by==="total_price"?"":"CAST(total_price AS FLOAT) as total_price, "}customer_joining_date,${compare !== "customer_joining_date"? " "+compare+",": ""} get_months(created_at) as created_month, get_months(customer_joining_date) as customer_joining_month, id FROM ?`,[filtered_data]);

    // Getting New Customers
    var new_customer = alasql(`SELECT GET_FIRST_ELEMENT(ARRAY(${compare !== "customer_joining_date"? compare: "customer_joining_month"})) as ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, customer_id FROM ? GROUP BY customer_id`,[res]);
    new_customer = alasql(`SELECT ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, COUNT(customer_id) as no_of_orders FROM ? GROUP BY ${compare !== "customer_joining_date"? compare: "customer_joining_month"}`,[new_customer]);

    // Getting old customer
    var old_customer = alasql(`SELECT GET_FIRST_ELEMENT(ARRAY(${compare !== "customer_joining_date"? compare: "customer_joining_month"})) as ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, SUM(${accumulated_by}) as total_accumulated_by, months_since_order, customer_id FROM ? GROUP BY customer_id, months_since_order`,[res]);
    old_customer = alasql(`SELECT ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, months_since_order, ROUND(SUM(total_accumulated_by)) as total_accumulated_by FROM ? GROUP BY ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, months_since_order`,[old_customer])

    let repeat_order = get_high_repeat_product(res, compare !== "customer_joining_date"? compare: "customer_joining_month", created_at_min, created_at_max);
    var cohort_data = convert_to_accumulated_cohort_data(new_customer,old_customer,repeat_order,compare !== "customer_joining_date"? compare: "customer_joining_month");

    return compare !== "customer_joining_date"? sort_sort_cohort_data(cohort_data, "New Customers"): sort_cohort_data_by_date(cohort_data, "Cohort");
}


function get_average_order_value_data(order_data, selected_filter, created_at_min, created_at_max, compare="customer_joining_date"){ 
    
    let filtered_data = apply_filter_on_data(order_data, selected_filter)

    var res = alasql(`SELECT customer_id, get_months_since_first_order(created_at, customer_joining_date) as months_since_order, created_at, CAST(total_price AS FLOAT) as total_price, customer_joining_date,${compare !== "customer_joining_date"? " "+compare+",": ""} get_months(created_at) as created_month, get_months(customer_joining_date) as customer_joining_month, id FROM ?`,[filtered_data]);

    // Getting New Customers
    var new_customer = alasql(`SELECT GET_FIRST_ELEMENT(ARRAY(${compare !== "customer_joining_date"? compare: "customer_joining_month"})) as ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, customer_id FROM ? GROUP BY customer_id`,[res]);
    new_customer = alasql(`SELECT ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, COUNT(customer_id) as no_of_orders FROM ? GROUP BY ${compare !== "customer_joining_date"? compare: "customer_joining_month"}`,[new_customer]);

    // Getting old customer
    var old_customer = alasql(`SELECT GET_FIRST_ELEMENT(ARRAY(${compare !== "customer_joining_date"? compare: "customer_joining_month"})) as ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, SUM(total_price) as total_price, months_since_order, customer_id, COUNT(id) as no_of_order FROM ? GROUP BY customer_id, months_since_order`,[res]);
    old_customer = alasql(`SELECT ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, months_since_order, ROUND(SUM(total_price)) as total_price, SUM(no_of_order) as no_of_order FROM ? GROUP BY ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, months_since_order`,[old_customer])

    let repeat_order = get_high_repeat_product(res, compare !== "customer_joining_date"? compare: "customer_joining_month", created_at_min, created_at_max);
    var cohort_data = convert_to_aov_cohort_data(new_customer,old_customer,repeat_order,compare !== "customer_joining_date"? compare: "customer_joining_month")

    return compare !== "customer_joining_date"? sort_sort_cohort_data(cohort_data, "New Customers"): sort_cohort_data_by_date(cohort_data, "Cohort");
}


function get_customer_purchased_x_times_data(order_data, selected_filter, created_at_min, created_at_max, compare="customer_joining_date"){
    let filtered_data = apply_filter_on_data(order_data, selected_filter)

    var res = alasql(`SELECT customer_id, created_at, CAST(total_price AS FLOAT) as total_price, customer_joining_date,${compare !== "customer_joining_date"? " "+compare+",": ""} get_months(created_at) as created_month, get_months(customer_joining_date) as customer_joining_month, id FROM ?`,[filtered_data]);

    // Getting New Customers
    var new_customer = alasql(`SELECT GET_FIRST_ELEMENT(ARRAY(${compare !== "customer_joining_date"? compare: "customer_joining_month"})) as ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, customer_id FROM ? GROUP BY customer_id`,[res]);
    new_customer = alasql(`SELECT ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, COUNT(customer_id) as no_of_orders FROM ? GROUP BY ${compare !== "customer_joining_date"? compare: "customer_joining_month"}`,[new_customer]);
    // Getting New Customers
    
    // Getting old customer
    var old_customer = alasql(`SELECT GET_FIRST_ELEMENT(ARRAY(${compare !== "customer_joining_date"? compare: "customer_joining_month"})) as ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, customer_id, COUNT(id) as no_of_orders FROM ? GROUP BY customer_id`,[res]);
    old_customer = alasql(`SELECT ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, no_of_orders, COUNT(customer_id) as old_customer_orders FROM ? GROUP BY ${compare !== "customer_joining_date"? compare: "customer_joining_month"}, no_of_orders`,[old_customer])
    
    let repeat_order = get_high_repeat_product(res, compare !== "customer_joining_date"? compare: "customer_joining_month", created_at_min, created_at_max);
    var cohort_data = convert_to_customer_purchased_x_times_cohort_data(new_customer,old_customer,repeat_order,compare !== "customer_joining_date"? compare: "customer_joining_month")

    return compare !== "customer_joining_date"? sort_sort_cohort_data(cohort_data, "New Customers"): sort_cohort_data_by_date(cohort_data, "Cohort");
}


function convert_to_accumulated_cohort_data(new_customer,old_customer,repeat_ltv_order,key){

    let cohort_data = [["Cohort", "New Customers", "Repeat %", "LTV","1", "2", "3", "4", "5+"]];

    if(["product_title","product_type","sku","customer_tags","tags","discount_codes"].includes(key)){
        let uniqueValues = Array.from(new Set(new_customer.map(item => item[key].split("<br />")).flat()));
        for(let unique_val_iter of uniqueValues){
            let temp_key = (unique_val_iter === null) || (unique_val_iter === "") ? "N/A" : unique_val_iter;
            let temp_data = [temp_key, 0, 0, 0, 0, 0, 0, 0, 0];
            let no_of_orders = 0;
            for(let iter of new_customer){
                if(iter[key].split("<br />").includes(unique_val_iter)){
                    temp_data[1] += iter["no_of_orders"];
                }
            }
            let filter_data = old_customer.filter((value) => { return value[key].split("<br />").includes(unique_val_iter)});
            for(let old_cust_iter of filter_data){
                if(old_cust_iter["months_since_order"] >= 0){
                    no_of_orders = no_of_orders < old_cust_iter["months_since_order"] ? old_cust_iter["months_since_order"] : no_of_orders;
                    temp_data[old_cust_iter["months_since_order"]+1 >=5 ? 8: old_cust_iter["months_since_order"]+4] += old_cust_iter["total_accumulated_by"]
                }
            }

            for(let idx of [4,5,6,7,8]){
                if((idx > 4) && (no_of_orders >= idx-4)){
                    temp_data[idx] = ((temp_data[idx] / temp_data[1]) + parseFloat(temp_data[idx-1]));
                }
                else{
                    temp_data[idx] = (temp_data[idx] / temp_data[1]);
                }
            }
    
            cohort_data.push(temp_data);
        }
    }
    else{
        for(let iter of new_customer){
            let temp_data = [iter[key], iter["no_of_orders"], 0, 0, 0, 0, 0, 0, 0];
            let filter_data = old_customer.filter((value) => { return value[key] === iter[key]})
            let no_of_orders = 0;
            for(let old_cust_iter of filter_data){
                if(old_cust_iter["months_since_order"] >= 0){
                    no_of_orders = no_of_orders < old_cust_iter["months_since_order"] ? old_cust_iter["months_since_order"] : no_of_orders;
                    temp_data[old_cust_iter["months_since_order"]+1 >= 5 ? 8: old_cust_iter["months_since_order"]+4] += old_cust_iter["total_accumulated_by"]
                }
            }
            for(let idx of [4,5,6,7,8]){
                if((idx > 4) && (no_of_orders >= idx-4)){
                    temp_data[idx] = ((temp_data[idx] / temp_data[1]) + parseFloat(temp_data[idx-1]));
                }
                else{
                    temp_data[idx] = (temp_data[idx] / temp_data[1]);
                }
            }
            cohort_data.push(temp_data);
        }
    }

    for(let i=1; i<cohort_data.length; i++){
        if(Object.keys(repeat_ltv_order).includes(cohort_data[i][0])){
            cohort_data[i][2] = repeat_ltv_order[cohort_data[i][0]][0];
            cohort_data[i][3] = repeat_ltv_order[cohort_data[i][0]][1];
        }
        // else{
        //     console.log(cohort_data[i][0])
        // }
    }

    return cohort_data;
}

function convert_to_customer_purchased_x_times_cohort_data(new_customer,old_customer,repeat_ltv_order,key){

    let cohort_data = [["Cohort", "New Customers", "Repeat %", "LTV","1", "2", "3", "4", "5+"]];

    if(["product_title","product_type","sku","customer_tags","tags","discount_codes"].includes(key)){
        let uniqueValues = Array.from(new Set(new_customer.map(item => item[key].split("<br />")).flat()));
        for(let unique_val_iter of uniqueValues){
            let temp_key = (unique_val_iter === null) || (unique_val_iter === "") ? "N/A" : unique_val_iter;
            let temp_data = [temp_key, 0, 0, 0, 0, 0, 0, 0, 0];
            for(let iter of new_customer){
                if(iter[key].split("<br />").includes(unique_val_iter)){
                    temp_data[1] += iter["no_of_orders"];
                }
            }
            let filter_data = old_customer.filter((value) => { return value[key].split("<br />").includes(unique_val_iter)});
            for(let old_cust_iter of filter_data){
                if(old_cust_iter["no_of_orders"] >= 1){
                    temp_data[old_cust_iter["no_of_orders"] >=5 ? 8: old_cust_iter["no_of_orders"]+3] += old_cust_iter["old_customer_orders"]
                }
            }
    
            cohort_data.push(temp_data);
        }
    }
    else{
        for(let iter of new_customer){
            let temp_key = (iter[key] === null) || (iter[key] === "") ? "N/A" : iter[key];
            let temp_data = [temp_key, iter["no_of_orders"], 0, 0, 0, 0, 0, 0, 0];
    
            let filter_data = old_customer.filter((value) => { return ((value[key] === null) || (value[key] === "") ? "N/A" : value[key]) === temp_key});
    
            for(let old_cust_iter of filter_data){
                if(old_cust_iter["no_of_orders"] >= 1){
                    temp_data[old_cust_iter["no_of_orders"] >=5 ? 8: old_cust_iter["no_of_orders"]+3] += old_cust_iter["old_customer_orders"]
                }
            }
    
            cohort_data.push(temp_data);
        }
    }

    for(let i=1; i<cohort_data.length; i++){
        if(Object.keys(repeat_ltv_order).includes(cohort_data[i][0])){
            cohort_data[i][2] = repeat_ltv_order[cohort_data[i][0]][0];
            cohort_data[i][3] = repeat_ltv_order[cohort_data[i][0]][1];
        }
        // else{
        //     console.log(cohort_data[i][0])
        // }
    }
    
    return cohort_data;
}

function convert_to_aov_cohort_data(new_customer,old_customer,repeat_ltv_order,key){
    let cohort_data = [["Cohort", "New Customers", "Repeat %", "LTV","1", "2", "3", "4", "5+"]];

    if(["product_title","product_type","sku","customer_tags","tags","discount_codes"].includes(key)){
        let uniqueValues = Array.from(new Set(new_customer.map(item => item[key].split("<br />")).flat()));
        for(let unique_val_iter of uniqueValues){
            let temp_key = (unique_val_iter === null) || (unique_val_iter === "") ? "N/A" : unique_val_iter;
            let temp_data = [temp_key, 0, 0, 0, 0, 0, 0, 0, 0];
            for(let iter of new_customer){
                if(iter[key].split("<br />").includes(unique_val_iter)){
                    temp_data[1] += iter["no_of_orders"];
                }
            }
            let filter_data = old_customer.filter((value) => { return value[key].split("<br />").includes(unique_val_iter)});
            let orders_arr = [0,0,0,0,0,0,0,0,0];
            for(let old_cust_iter of filter_data){
                if(old_cust_iter["months_since_order"] >= 0){
                    temp_data[old_cust_iter["months_since_order"]+1 >=5 ? 8: old_cust_iter["months_since_order"]+4] += old_cust_iter["total_price"];
                    orders_arr[old_cust_iter["months_since_order"]+1 >=5 ? 8: old_cust_iter["months_since_order"]+4] += old_cust_iter["no_of_order"];
                }
            }

            for(let idx of [4,5,6,7,8]){
                if(orders_arr[idx]){
                    temp_data[idx] = (temp_data[idx] / orders_arr[idx]);
                }
            }
    
            cohort_data.push(temp_data);
        }
    }
    else{
        for(let iter of new_customer){
            let temp_key = (iter[key] === null) || (iter[key] === "") ? "N/A" : iter[key];
            let temp_data = [temp_key, iter["no_of_orders"], 0, 0, 0, 0, 0, 0, 0];
    
            let filter_data = old_customer.filter((value) => { return ((value[key] === null) || (value[key] === "") ? "N/A" : value[key]) === temp_key});
            let orders_arr = [0,0,0,0,0,0,0,0,0];
            for(let old_cust_iter of filter_data){
                if(old_cust_iter["months_since_order"] >= 0){
                    temp_data[old_cust_iter["months_since_order"]+1 >=5 ? 8: old_cust_iter["months_since_order"]+4] += old_cust_iter["total_price"];
                    orders_arr[old_cust_iter["months_since_order"]+1 >=5 ? 8: old_cust_iter["months_since_order"]+4] += old_cust_iter["no_of_order"];
                }
            }

            for(let idx of [4,5,6,7,8]){
                if(orders_arr[idx]){
                    temp_data[idx] = (temp_data[idx] / orders_arr[idx]);
                }
            }
    
            cohort_data.push(temp_data);
        }
    }

    for(let i=1; i<cohort_data.length; i++){
        if(Object.keys(repeat_ltv_order).includes(cohort_data[i][0])){
            cohort_data[i][2] = repeat_ltv_order[cohort_data[i][0]][0];
            cohort_data[i][3] = repeat_ltv_order[cohort_data[i][0]][1];
        }
        // else{
        //     console.log(cohort_data[i][0])
        // }
    }
    
    return cohort_data;
}

function sort_sort_cohort_data(data, order_by){
    var header = data[0];
    var rows = data.splice(1);

    var json_data = [];
    for(let row of rows){
        let row_dict = {};
        for(let i=0; i<row.length; i++){
            row_dict[header[i]] = row[i];
        }
        json_data.push(row_dict);
    }

    let sorted_data = alasql(`SELECT * FROM ? ORDER BY [${order_by}] DESC`,[json_data]);

    let final_data = [header];
    for(var rows_iter of sorted_data){
        let row_arr = []
        for(let col of header){
            row_arr.push(rows_iter[col]);
        }
        final_data.push(row_arr);
    }

    return final_data
}

function sort_cohort_data_by_date(data, order_by){
    var header = data[0];
    var rows = data.splice(1);

    var json_data = [];
    for(let row of rows){
        let row_dict = {};
        for(let i=0; i<row.length; i++){
            row_dict[header[i]] = row[i];
        }
        json_data.push(row_dict);
    }

    let sorted_data = alasql(`SELECT * FROM ? ORDER BY GET_MONTH_FOR_SORT([${order_by}])`,[json_data]);

    let final_data = [header];
    for(var rows_iter of sorted_data){
        let row_arr = []
        for(let col of header){
            row_arr.push(rows_iter[col]);
        }
        final_data.push(row_arr);
    }

    return final_data
}

function get_high_repeat_product(data, repeat_by, created_at_min, created_at_max){
    // Getting New Customers
    let res = alasql('SELECT * FROM ? ORDER BY customer_id,created_at',[data]);
    res = alasql(`SELECT customer_id, GET_FIRST_ELEMENT(ARRAY(${repeat_by})) as ${repeat_by}, SUM(total_price) as revenue, COUNT(DISTINCT id) as no_of_orders FROM ? GROUP BY customer_id ORDER BY ${repeat_by}`,[res]);
    
    let final_res = [];    
    for(const iter of res){
        for(const filter_on_iter of Array.from(new Set(iter[repeat_by].split("<br />")))){
            let filter_on_and_value_dict = {}
            filter_on_and_value_dict[repeat_by] = filter_on_iter === "" ? "N/A": filter_on_iter;
            filter_on_and_value_dict["no_of_orders"] = iter["no_of_orders"];
            filter_on_and_value_dict["revenue"] = iter["revenue"];
            filter_on_and_value_dict["customer_id"] = iter["customer_id"];
            final_res.push(filter_on_and_value_dict);
        }
    }
    
    final_res = alasql(`SELECT ${repeat_by}, COALESCE(ROUND(((COUNT(case when no_of_orders > 1 then customer_id else null end)/COUNT(customer_id))*100),1)) as repeat_per, COALESCE(ROUND((SUM(revenue)/COUNT(customer_id)),1)) as LTV FROM ? GROUP BY ${repeat_by}`,[final_res]);

    let final_dict = {};

    for(let iter of final_res){
        final_dict[iter[repeat_by]] = [iter["repeat_per"], iter["LTV"]];
    }

    // console.log(Object.keys(final_dict).length);

    return final_dict;
}