import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../images/scalex_ai_logo.png"
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Cookies from 'js-cookie';

function Login({ app_url }) {
  const [login_loading_btn, setLoginLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    if((email === null) || (email === undefined) || (email === '')){
        setLoginLoading(false);
        toast.error("Please enter your Email");
    }
    else if((password === null) || (password === undefined) || (password === '')){
        setLoginLoading(false);
        toast.error("Please enter your Password");
    }
    else{
        try {
            axios.post(app_url+"/login",{email:email, password:password}, {withCredentials:true})
            .then(res => res.data)
            .then(data => {
                console.log(data)
                setLoginLoading(false);
                navigate('/creative_analysis', {replace: true});
                window.location.reload();
            })
            .catch(err => {
                toast.error("Please try after some time.");
                console.log(err);
                setLoginLoading(false);
            })
        } catch (error) {
            toast.error("Please try after some time.");
            setLoginLoading(false);
        }
    }

  };

  return (
    <>
        <Toaster />
        <div style={{ width: '100vw', height: '100vh' }}>
            <Row className="justify-content-md-center" style={{ margin: '0' }} lg={2} xs={1}>
                {/* Details side */}
                <Col className='d-flex align-items-center justify-content-center' style={{ backgroundColor: 'rgb(19,40,72)', width:"50vw", height:"100vh", padding:"5% 10%"}}>
                    <div style={{ width: '100%', textAlign:"center" }}>
                        <p style={{color:"white", fontSize:"5rem", fontWeight:"bold"}}>ScaleX AI</p>
                    </div>
                </Col>
                
                {/* Login Side */}
                <Col className='d-flex align-items-center justify-content-center' style={{padding:"5% 12%"}}>
                    <div style={{ width: '100%', boxShadow: "10px 15px 15px rgba(17, 44, 123, .1)", border: "2px solid grey", borderRadius:"0.5rem", padding: "10%"}}>
                        <h1 className="text-center">Login</h1>
                        <br />
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className='mb-3' controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.trim())}
                                />
                            </Form.Group>

                            <Form.Group className='mt-3' controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value.trim())}
                                />
                            </Form.Group>

                            <br />

                            { login_loading_btn ?
                                <Button variant="primary" type="submit" className="w-100 mt-3" style={{ backgroundColor: 'rgb(19,40,72)', color:"white", borderColor: '#007bff' }}>
                                    <Spinner size='sm' />
                                </Button>
                            :
                                <Button variant="primary" type="submit" className="w-100 mt-3" style={{ backgroundColor: 'rgb(19,40,72)', color:"white", borderColor: '#007bff' }}>
                                    Login
                                </Button>
                            }

                        </Form>

                        <br />

                        <hr />

                        <Link to={"https://admin.shopify.com/"} target="_blank"><Button variant="primary" type="submit" className="w-100 mt-3" style={{ backgroundColor: 'rgb(19,40,72)', color:"white", borderColor: '#007bff' }}>Login with Shopify</Button></Link>

                    </div>
                </Col>
            </Row>
        </div>
    </>
  );
}

export default Login;
