import React, {useState, useEffect, useRef} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import alasql from 'alasql';

alasql.fn.add_id_string = function(product_id){
    return `${"gid://shopify/Product/"+product_id}`
}

function MetaCreativeGeneration({ViewedPage, shop_domain, app_url}) {
    ViewedPage(14);

    const [loading, setLoading] = useState(true); 
    const [creative_button_loading, setCreativeButtonLoading] = useState(false); 
    const [save_to_sheet_loading, setSaveToSheetLoading] = useState(false); 
    const [selected_product_creative_image_idx_arr, setSelectedProductCreativeImageIndex] = useState([]); 
    const [product_creative_image_urls, setProductCreativeImageURLs] = useState([]); 
    const [product_headline_json, setProductHeadlineJson] = useState([]); 
    const [selected_product_data, setSelectedProductData] = useState({
        "id":"",
        "title":"",
        "max_price":"",
        "price":"",
        "description":"",
        "productType":"",
        "featured_image":"",
        "images":"",
        "headline":"",
        "short_title":"",
        "product_type":"",
        "tags":"",
    })

    const [selected_headline_arr, setSelectedHeadline] = useState([])
    const headline_types_dict = {
        "All":["product_benefits_focused","first_time_customers","existing_customers","sale_focused","new_arrival_focused","hindi","marathi","kannada","problem_focused","cost_saving_focused"],
        "New_Customers":['first_time_customers'],
        "Existing_Customers":['existing_customers'],
        "Sale":['sale_focused'],
        "New_Arrivals":['new_arrival_focused'],
        "Best_Sellers":['best_sellers_focused'],
        "Personalization":['hindi',"marathi","kannada"],
        "Reviews":[],
        "Best_Performing":[],
        "GIF":['gif'],
    };

    
    const [loadedImages, setLoadedImages] = useState([]);
    const [page, setPage] = useState(1);

    const [selected_headline_type, setSelectedHeadlineType] = useState([])
    const loader = useRef(null);

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/shopify/get_shopify_all_products",{
                    shop_domain:Cookies.get('shop'),
                }, {withCredentials:true})
                .then(prod_request => prod_request.data)
                .then(prod_data => {
                    console.log(prod_data.data)
                    setProductHeadlineJson(prod_data.data.product_headline)
                    setLoading(false);
                })
                .catch(prod_err => {
                    setLoading(false);
                    toast.error("No Data to Show.");                    
                    console.log(prod_err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }, [app_url, shop_domain]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0
        });
        if (loader.current) {
            observer.observe(loader.current);
        }
    },[loader])

    const handleObserver = (entities) => {
        const target = entities[0];
        console.log("-----------------------")
        console.log("Target")
        console.log(target)
        console.log("-----------------------")
        if (target.isIntersecting) {
            setPage((page) => {
                const nextPage = page + 1;
                loadMoreImages(product_creative_image_urls, nextPage);
                return nextPage;
            });
        }
    };
    const loadMoreImages = (images, page) => {
        const itemsPerPage = 6;
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = page * itemsPerPage;
        const newImages = images.slice(startIndex, endIndex);
        console.log("--------------------------------------------------------")
        console.log("Loader Images")
        console.log((prevImages) => [...prevImages, ...newImages])
        console.log("--------------------------------------------------------")
        setLoadedImages((prevImages) => [...prevImages, ...newImages]);
    };

    const handleSelectedHeadline = (value) => {
        
        let selected_div = document.getElementById(value.split(" || tags=")[0].replaceAll(" ","_"));
        let temp_selected_headline_arr = [...selected_headline_arr];
        if(temp_selected_headline_arr.includes(value)){
            selected_div.className = ""
            temp_selected_headline_arr.splice(temp_selected_headline_arr.indexOf(value),1)
        }
        else{
            selected_div.className = "selected-headline-border"
            temp_selected_headline_arr.push(value)
        }
        // console.log(temp_selected_headline_arr)
        setSelectedHeadline(temp_selected_headline_arr);
    }

    const save_to_sheet = () => {
        try {
            if(Cookies.get('shop')){
                setSaveToSheetLoading(true);
                console.log(selected_product_data)
                console.log(product_creative_image_urls.filter((val,idx) => {return selected_product_creative_image_idx_arr.includes(idx)}))
                let sheet_data = [];
                product_creative_image_urls.filter((val,idx) => {return selected_product_creative_image_idx_arr.includes(idx)}).forEach((val,idx) => {
                    sheet_data.push([selected_product_data["id"],selected_product_data["title"],selected_product_data["description"],val]);
                })
                axios.post(app_url+"/api/shopify/send_creative_to_spreadhseet",{
                    shop_domain:Cookies.get('shop'),
                    sheet_data:sheet_data,
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    setSaveToSheetLoading(false);
                    toast.success("Uploaded to sheet successfully.");
                })
                .catch(err => {
                    setSaveToSheetLoading(false);
                    toast.error("Please try after some time.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("Please try after some time.");
            console.log(err)
        }
    }

    const generate_creative = (e) => {
        e.preventDefault()

        console.log({
            "id":selected_product_data["id"],
            "price":selected_product_data["price"],
            "title":selected_product_data["title"],
            "short_title":selected_product_data["short_title"],
            "headline_arr":selected_headline_arr,
        })
    
        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/shopify/generate_creative_meta",
                {
                    "product_id":selected_product_data["id"],
                    "price":selected_product_data["price"],
                    "product_title":selected_product_data["title"],
                    "short_title":selected_product_data["title"],
                    "headline_arr":selected_headline_arr,
                }, {withCredentials:true})
                pollCreativeStatus();
            }
        }
        catch(err){
            setCreativeButtonLoading(false);
            toast.error("Please try after some time.");
            console.log(err)
        }
    }

    const pollCreativeStatus = () => {
        setCreativeButtonLoading(true);
        const timeout = 60000; // 60 seconds
        const pollInterval = 5000; // 5 seconds
        const startTime = Date.now();
        var current_remaining_headline = selected_headline_arr;
        const intervalId = setInterval(async () => {
            try {
                const response = await axios.get(app_url+`/api/shopify/get_pending_creative?product_id=${selected_product_data["id"]}&headline_arr=${JSON.stringify(current_remaining_headline)}`, {withCredentials:true});
                if(response.data.success){
                    const { creatives_arr,remaining_headline,status } = response.data.data;
                    console.log(current_remaining_headline)
                    console.log(remaining_headline)
                    current_remaining_headline = remaining_headline;
                    let temp_product_creative_image_urls = [product_creative_image_urls,creatives_arr].flat();
                    setProductCreativeImageURLs([...new Set(temp_product_creative_image_urls)])
                    if (status === "completed") {
                        clearInterval(intervalId);
                        console.log('Polling Completed.');
                        setCreativeButtonLoading(false);
                    }
                }
                if (Date.now() - startTime > timeout) {
                    clearInterval(intervalId);
                    console.error('Polling timed out.');
                    setCreativeButtonLoading(false);
                }   
            } catch (error) {
                setCreativeButtonLoading(false);
                console.error('Error checking status:', error);
            }
        }, pollInterval);
    };
       


    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Creative Ideation</h2>
                            </div>

                            <Container className='my-4'>
                                <h6>Select Product</h6>
                                <div className='product-selection-div justify-content-md-left'>
                                    {product_headline_json.map((row, idx) => { return (
                                        <div className='product-div p-2'>
                                            <Card className='creative-generation-product-image' id={`select-product-${row["id"]}`} onClick={(e) => {
                                                // setLoading(true);
                                                // setTimeout(() => {
                                                //     setLoading(false);
                                                //     setTimeout(() => {
                                                        for(let div_iter of document.getElementsByClassName('creative-generation-product-image')){
                                                            div_iter.className = "creative-generation-product-image card";
                                                        }
                                                        let parentNode = document.getElementById(`select-product-${row["id"]}`);
                                                        parentNode.className = "creative-generation-product-image card selected-product-box-shadow";
                                                        let temp_selected_target_data = product_headline_json[idx];
                                                        setSelectedProductData(temp_selected_target_data);
                                                        setSelectedHeadlineType(["All"])
                                                        setSelectedHeadline([])
                                                        for(let val of Object.keys(headline_types_dict)){
                                                            let temp_selected_headline_type_div = document.getElementById(val);
                                                            if((temp_selected_headline_type_div !== undefined) && (temp_selected_headline_type_div !== null)){
                                                                temp_selected_headline_type_div.className = val === "All" ? "selected-headline-type-div" : ""
                                                            }
                                                        }
                                                //     }, 100);
                                                // }, 500);
                                            }}>                                                
                                                <Card.Img variant="top" height={150} style={{minHeight:"150px"}} src={`${row["featured_image"]}`} />
                                                <Card.Body>
                                                    <Card.Text><on_hover_tooltip title={row["title"]}>{row["title"].substring(0,45)+"..."}</on_hover_tooltip></Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>)
                                    })}
                                </div>
                            </Container>

                            <div style={{display:selected_product_data["title"] !== "" ? "block" : "none"}}>
                                <h6 className='mx-2'>Select Communication Theme</h6>
                                <Container className='my-4 mx-2 px-4' style={{ backgroundColor:"white", width:"98%", borderRadius:"0.5rem"}}>
                                    <Row className='justify-content-md-left' lg={10} xs={10}>
                                    {(Object.keys(headline_types_dict)).map((val,idx) => {
                                            return (
                                                <Col className='py-3 px-2'>
                                                    <div id={val} style={{cursor:"pointer", border:"1px solid #cdcdcd", display: "flex", alignItems:"center", padding:"0.5rem",borderRadius:"0.5rem", height:"3.5rem", textAlign:"center", justifyContent:"center", fontSize:"small", backgroundColor:"white", boxShadow:"5px 5px 10px rgba(17,44,123,.1)"}} onClick={(e) => {
                                                        e.preventDefault();
                                                        let temp_selected_headline_type = [];
                                                        if(val === "All"){
                                                            temp_selected_headline_type = ["All"];
                                                            setSelectedHeadlineType(["All"]);
                                                            setSelectedHeadline([]);
                                                        }
                                                        else{
                                                            if(selected_headline_type.includes(val)){
                                                                temp_selected_headline_type = [...selected_headline_type];
                                                                temp_selected_headline_type = temp_selected_headline_type.filter(x => ((x !== val) && (x !== "All")));
                                                                setSelectedHeadlineType(temp_selected_headline_type);
                                                                setSelectedHeadline([]);
                                                            }else{
                                                                temp_selected_headline_type = [...selected_headline_type];
                                                                temp_selected_headline_type = temp_selected_headline_type.filter(x => (x !== "All"));
                                                                temp_selected_headline_type.push(val)
                                                                setSelectedHeadlineType(temp_selected_headline_type);
                                                                setSelectedHeadline([]);
                                                            }
                                                        }
                                                        Object.keys(headline_types_dict).forEach((key) => {
                                                            document.getElementById(key).className = temp_selected_headline_type.includes(key) ? "selected-headline-type-div" : ""
                                                        })
                                                    }}>
                                                        {(val === "Personalization") && 
                                                            <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 200 }}
                                                            overlay={(props) => {
                                                                return (
                                                                <Tooltip id="button-tooltip" {...props}>
                                                                    Showing personalized headlines for the top 5 trending cities over the last 30 days
                                                                </Tooltip>);
                                                            }}
                                                            >
                                                                <span>{val.replace("_"," ")}</span>
                                                            </OverlayTrigger>
                                                        }
                                                        {(val !== "Personalization") && 
                                                            <span>{val.replace("_"," ")}</span>
                                                        }
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Container>
                            </div>

                            { ((selected_product_data["title"] !== "") && (selected_headline_type.length > 0)) &&
                                <div>
                                    <Container className='my-4 mx-2' style={{backgroundColor:"white", width:"98%", borderRadius:"0.5rem", padding:"10px 20px"}}>
                                        <Row className='justify-content-md-left' lg={4} xs={1}>
                                            {(product_headline_json.find((item) =>  (item["id"] === (selected_product_data["id"].replace("gid://shopify/Product/",""))))['headline'].substring(2, product_headline_json.find((item) =>  (item["id"] === (selected_product_data["id"].replace("gid://shopify/Product/",""))))['headline'].length - 2).split("','")).map((val,idx) => {
                                                let temp_selected_headline_type = [];
                                                for(let iter of selected_headline_type){
                                                    temp_selected_headline_type.push(headline_types_dict[iter])
                                                }
                                                if((selected_headline_type.includes("Personalization")) && (val.split(" || tags=")[1] === "product_benefits_focused")){
                                                    return (
                                                        ['Bangalore','Pune','Gurgaon','Mumbai'].map((cities) => {
                                                            return (
                                                                <Col className='p-3'>
                                                                    <div  id={val.split(" || tags=")[0].replaceAll(" ","_")} style={{cursor:"pointer", border:"1px solid #cdcdcd", display: "flex", alignItems:"center", height:"5rem", padding:"1rem", borderRadius:"0.5rem", boxShadow:"5px 5px 10px rgba(17,44,123,.1)"}} onClick={(e) => {
                                                                        handleSelectedHeadline(val);
                                                                    }}>
                                                                        <span>
                                                                            Hello {cities},
                                                                            <br />
                                                                            {val.split(" || tags=")[0]}
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })
                                                    )
                                                }
                                                if(temp_selected_headline_type.flat().includes(val.split(" || tags=")[1] )){
                                                    return (
                                                        <Col className='p-3'>
                                                            <div  id={val.split(" || tags=")[0].replaceAll(" ","_")} style={{cursor:"pointer", border:"1px solid #cdcdcd", display: "flex", alignItems:"center", height:"5rem", padding:"1rem", borderRadius:"0.5rem", boxShadow:"5px 5px 10px rgba(17,44,123,.1)"}} onClick={(e) => {
                                                                handleSelectedHeadline(val);
                                                            }}>
                                                                <span>
                                                                    {val.split(" || tags=")[0]}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                                else{
                                                    return null;
                                                }
                                            })}
                                        </Row>
                                    </Container>
                                    <div style={{textAlign:"end", width:"99.5%"}}>
                                    { ! creative_button_loading ?
                                        <Button className='mx-2' style={{width:"11rem", height:"3rem"}} variant='primary' onClick={generate_creative}>Generate Creatives</Button>
                                        :
                                        <Button className='mx-2' style={{width:"11rem", height:"3rem"}} variant='primary' disabled >
                                            <div className='main_conatiner_spinner'>
                                                <Spinner size='sm' animation="border"/> &nbsp; Generating
                                            </div>
                                        </Button>
                                    }
                                    </div>
                                </div>
                            }


                            { product_creative_image_urls.length > 0 ?
                                <>
                                <h6 className='mx-2'>Select Creative(s)</h6>
                                <Container className='my-4 mx-2' style={{backgroundColor:"white", width:"98%", borderRadius:"0.5rem", padding:"10px 20px"}}>
                                    <Row className='justify-content-md-left' lg={3} xs={1}>
                                        {loadedImages.map((val,idx) => {
                                            return (
                                                <Col className='p-3'>
                                                    <Card className='product-creative-image-div' style={{height:"350px", position:"relative", overflow:"hidden",boxShadow:"5px 5px 10px rgba(17,44,123,.1)"}}  key={idx} onClick={(e) => {
                                                        e.preventDefault();
                                                        let prod_creative_div_ele = document.getElementsByClassName("product-creative-image-div"); 
                                                        let temp_selected_creative_idx_arr= selected_product_creative_image_idx_arr;
                                                        console.log(selected_product_creative_image_idx_arr)
                                                        if(temp_selected_creative_idx_arr.includes(idx)){
                                                            temp_selected_creative_idx_arr = temp_selected_creative_idx_arr.filter((creative_val_idx) => creative_val_idx !== idx);
                                                            Array.from(prod_creative_div_ele).forEach((el,el_idx) => {
                                                                el.className = temp_selected_creative_idx_arr.includes(el_idx) ? "selected-creative-border product-creative-image-div" : "product-creative-image-div";
                                                            });
                                                            setSelectedProductCreativeImageIndex(temp_selected_creative_idx_arr); 
                                                        }
                                                        else{
                                                            temp_selected_creative_idx_arr.push(idx);
                                                            Array.from(prod_creative_div_ele).forEach((el,el_idx) => {
                                                                el.className = temp_selected_creative_idx_arr.includes(el_idx) ? "selected-creative-border product-creative-image-div" : "product-creative-image-div";
                                                            });
                                                            setSelectedProductCreativeImageIndex(temp_selected_creative_idx_arr); 
                                                        }
                                                    }}>
                                                    <Card.Img variant="top" height={350} src={val} />
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                        <div ref={loader}></div>
                                    </Row>
                                </Container>
                                <div style={{textAlign:"end", width:"99.5%"}}>
                                    { ! save_to_sheet_loading ?
                                        <Button className='mx-2' style={{width:"11rem", height:"3rem"}} variant='primary' onClick={save_to_sheet}>Upload To Sheet</Button> 
                                        :
                                        <Button className='mx-2' style={{width:"11rem", height:"3rem"}} variant='primary' disabled >
                                            <div className='main_conatiner_spinner'>
                                                <Spinner size='sm' animation="border"/> &nbsp; Uploading
                                            </div>
                                        </Button>
                                    }
                                </div>

                                </>
                                :
                                ""
                            }

                            {/* { selected_headline_arr.includes("Sophisticated Comfort") ?
                                <>
                                <h6 className='mx-2'>Select Creative(s)</h6>
                                <Container className='my-4 mx-2' style={{backgroundColor:"white", width:"98%", borderRadius:"0.5rem", padding:"10px 20px"}}>
                                    <Row className='justify-content-md-left' lg={3} xs={1}>
                                        {[].map((val,idx) => {
                                            return (
                                                <Col className='p-3'>
                                                    <Card style={{height:"300px", position:"relative", overflow:"hidden",}}>
                                                    <Card.Img variant="top" height={300} src={val} />
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Container>
                                </>
                                :
                                ""
                            } */}
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default MetaCreativeGeneration