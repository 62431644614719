import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { DateRangePicker } from "rsuite";
// import number_1 from '../images/number-1.png';
// import number_2 from '../images/number-2.png';
// import number_3 from '../images/number-3.png';
import alasql from "alasql";
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import FormSelect from 'react-bootstrap/FormSelect';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function MetricsDeepdive({ViewedPage, shop_domain, app_url}) {
    ViewedPage(1);

    const [loading, setLoading] = useState(true);
    const [utm_source_select_dict, setUTMSource] = useState([]);
    const [utm_medium_select_dict, setUTMMedium] = useState([]);
    const [selected_date_range_value, setDateRange] = useState([]);
    const [order_raw_data, setOrderRawData] = useState([]);
    const [utm_source_data, setUtmSourceData] = useState([]);
    const [utm_medium_data, setUtmMediumData] = useState([]);
    const [utm_campaign_data, setUtmCampaignData] = useState([]);

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
                setDateRange([created_at_min, created_at_max]);
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    setOrderRawData(formated_data);

                    var { utm_source_list, utm_medium_list } = get_utm_paramters_list(formated_data);
                    setUTMSource(utm_source_list);
                    setUTMMedium(utm_medium_list);
                    setUtmSourceData(get_utm_source_data(formated_data, created_at_min, created_at_max));
                    setUtmMediumData(get_utm_medium_data(formated_data, created_at_min, created_at_max));
                    setUtmCampaignData(get_utm_campaign_data(formated_data, created_at_min, created_at_max));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }, [app_url, shop_domain]);


    const handleDateChange = async (date_range) => {
        setDateRange(date_range);
        var created_at_max = new Date();
        var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
        if(date_range.length > 0){
            created_at_min = date_range[0];
            created_at_max = date_range[1];
        }
        var utm_source = document.getElementById("utm_source").value;
        var utm_medium = document.getElementById("utm_medium").value;
        setLoading(true);

        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    console.log(formated_data)
                    setOrderRawData(formated_data);

                    var { utm_source_list, utm_medium_list } = get_utm_paramters_list(formated_data);
                    setUTMSource(utm_source_list);
                    setUTMMedium(utm_medium_list);

                    formated_data = filter_data(formated_data, utm_source, utm_medium);
                    setUtmSourceData(get_utm_source_data(formated_data, created_at_min, created_at_max));
                    setUtmMediumData(get_utm_medium_data(formated_data, created_at_min, created_at_max));
                    setUtmCampaignData(get_utm_campaign_data(formated_data, created_at_min, created_at_max));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }

    }
    
    const handleFilters = async (e) => {
        e.preventDefault();
        var utm_medium = document.getElementById("utm_medium").value;
        var utm_source = document.getElementById("utm_source").value;
        setLoading(true);
        try{
            var formated_data = filter_data(order_raw_data, utm_source, utm_medium);
            setUtmSourceData(get_utm_source_data(formated_data, selected_date_range_value[0], selected_date_range_value[1]));
            setUtmMediumData(get_utm_medium_data(formated_data, selected_date_range_value[0], selected_date_range_value[1]));
            setUtmCampaignData(get_utm_campaign_data(formated_data, selected_date_range_value[0], selected_date_range_value[1]));
            setLoading(false);
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Metrics Deepdive Report</h2>
                            </div>

                            <Container className='mt-3'>
                                <Row className='justify-content-md-left' lg={3} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker id='date_range' defaultValue={selected_date_range_value} format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">UTM Source</Label>
                                        <br />
                                        <FormSelect defaultValue={"all"} id='utm_source' onChange={handleFilters}>
                                            { utm_source_select_dict.map((source_iter) => <option key={source_iter["value"]} value={source_iter["value"]}>{source_iter["label"]}</option>)}
                                        </FormSelect>                                        
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">UTM Medium</Label>
                                        <br />
                                        <FormSelect defaultValue={"all"} id='utm_medium' onChange={handleFilters}>
                                            { utm_medium_select_dict.map((medium_iter) => <option key={medium_iter["value"]} value={medium_iter["value"]}>{medium_iter["label"]}</option>)}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Container>

                            <div className='main-accordion-body mt-4 p-2' id='utm-performance-page'>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            {/* <img src={number_1} alt='1' width={"15px"} height={"15px"} />  */}
                                            <b style={{marginLeft:"10px"}}>Performance by UTM Campaign</b>
                                        </Accordion.Header>
                                        <Accordion.Body style={{padding:"0 !important"}}>
                                            <Chart
                                                chartType="Table"
                                                width={"100%"}
                                                data={utm_campaign_data}
                                                options={{
                                                    width: '100%',
                                                    curveType: "function",
                                                    legend: { position: "bottom" },
                                                    pageSize: 10,
                                                    cssClassNames:{
                                                        tableCell :"channel_performance_table_cell",
                                                        headerCell :"channel_performance_header_cell",
                                                        tableRow :"channel_performance_table_row", 
                                                        oddTableRow :"channel_performance_old_table_row" 
                                                    }
                                                }}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            {/* <img src={number_2} alt='2' width={"15px"} height={"15px"} />  */}
                                            <b style={{marginLeft:"10px"}}>Performance by UTM Source</b>
                                        </Accordion.Header>
                                        <Accordion.Body style={{padding:"0 !important"}}>
                                            <Chart
                                                chartType="Table"
                                                width={"100%"}
                                                data={utm_source_data}
                                                options={{
                                                    width: '100%', 
                                                    curveType: "function",
                                                    legend: { position: "bottom" },
                                                    pageSize: 10,
                                                    cssClassNames:{
                                                        tableCell :"channel_performance_table_cell",
                                                        headerCell :"channel_performance_header_cell",
                                                        tableRow :"channel_performance_table_row", 
                                                        oddTableRow :"channel_performance_old_table_row"
                                                    }
                                                }}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            {/* <img src={number_3} alt='3' width={"15px"} height={"15px"} />  */}
                                            <b style={{marginLeft:"10px"}}>Performance by UTM Medium</b>
                                        </Accordion.Header>
                                        <Accordion.Body style={{padding:"0 !important"}}>
                                            <Chart
                                                chartType="Table"
                                                width={"100%"}
                                                data={utm_medium_data}
                                                options={{
                                                    width: '100%',
                                                    curveType: "function",
                                                    legend: { position: "bottom" },
                                                    pageSize: 10,
                                                    cssClassNames:{
                                                        tableCell :"channel_performance_table_cell",
                                                        headerCell :"channel_performance_header_cell",
                                                        tableRow :"channel_performance_table_row", 
                                                        oddTableRow :"channel_performance_old_table_row"
                                                    }
                                                }}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )    
}

export default MetricsDeepdive


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
  }


function jsonToArrayObject(res, graph_columns) {
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      for (let column_name of columns) {
        res_arr.push(obj[column_name]);
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
   return resultArray;
}


function formate_shopify_data(data){
    var res = alasql('SELECT country, created_at, getDateString(created_at) as Date, currency_code, CAST(current_total_price AS FLOAT) as current_total_price, customer_id, customer_joining_date, customer_tags, discount_code, discount_codes, id, last_visit_campaign as utm_campaign, last_visit_content as utm_content, last_visit_medium as utm_medium, last_visit_source as utm_source, last_visit_term as utm_term, product_title, product_type, profit, sku, tags, CAST(total_price AS FLOAT) as total_price FROM ?',[data]);

    return res;
}


function get_utm_paramters_list(data){
    var utm_medium_raw_list = alasql('SELECT DISTINCT utm_medium FROM ?',[data]);
    var utm_source_raw_list = alasql('SELECT DISTINCT utm_source FROM ?',[data]);
    
    var final_utm_source_list = [{"label":"All", "value":"all"}];
    var final_utm_medium_list = [{"label":"All", "value":"all"}];

    for(var source_iter of utm_source_raw_list){
        if(source_iter["utm_source"] !== ""){
            final_utm_source_list.push({
                "label": source_iter["utm_source"],
                "value": source_iter["utm_source"]
            });
        }
    }
    
    for(var medium_iter of utm_medium_raw_list){
        if(medium_iter["utm_medium"] !== ""){
            final_utm_medium_list.push({
                "label": medium_iter["utm_medium"],
                "value": medium_iter["utm_medium"]
            });
        }
    }


    return { 
        utm_source_list:final_utm_source_list,
        utm_medium_list:final_utm_medium_list
    };
}


function filter_data(data, utm_source, utm_medium){
    if((data.length > 0) && (utm_source !== "all") && (utm_source !== null) && (utm_source !== undefined)){
        data = alasql(`SELECT * FROM ? WHERE utm_source = "${utm_source}"`,[data]);
    }
    if((data.length > 0) && (utm_medium !== "all") && (utm_medium !== null) && (utm_medium !== undefined)){
        data = alasql(`SELECT * FROM ? WHERE utm_medium = "${utm_medium}"`,[data]);
    }
    return data;
}


function get_utm_source_data(data, created_at_min, created_at_max){
    var res = alasql(`SELECT utm_source, ROUND(SUM(total_price)) as total_price, ROUND(SUM(total_price)/COUNT(id)) as AOV, ROUND((SUM(profit)/SUM(total_price)) *100 )+ "%" as margin_per, ROUND(((SUM(total_price) - SUM(current_total_price))/SUM(total_price))*100) + "%" as return_per, ROUND((COUNT(DISTINCT case when (DATE(customer_joining_date) >= DATE('${created_at_min}') AND DATE(customer_joining_date) <= DATE('${created_at_max}')) then customer_id else null end) / COUNT(DISTINCT customer_id)) * 100) + "%" as new_customer_per, ROUND(SUM(profit)) as profit, ROUND(SUM(current_total_price)) as current_total_price  FROM ? GROUP BY utm_source`,[data]);

    var repeat_cust_res = alasql(`SELECT customer_id, FIRST(utm_source) as utm_source, count(id) as no_of_order FROM ? WHERE (DATE(customer_joining_date) >= DATE('${created_at_min}') AND DATE(customer_joining_date) <= DATE('${created_at_max}')) GROUP BY customer_id`,[data]);
    repeat_cust_res = alasql(`SELECT utm_source, ROUND((COUNT(case when no_of_order > 1 then customer_id else null end) / COUNT(customer_id)) * 100) + "%" as repeat_customer_per FROM ? GROUP BY utm_source`,[repeat_cust_res]);

    var final_res = alasql(`SELECT * FROM ? AS a LEFT JOIN ? AS b ON a.utm_source = b.utm_source`, [res,repeat_cust_res]);
    final_res = alasql(`SELECT 
        IF(utm_source = '', 'N/A', utm_source) AS utm_source, 
        total_price, 
        AOV, 
        IF(((margin_per = "NaN%") || (margin_per = "-Infinity%") || (margin_per = "Infinity%")), "0%", COALESCE(margin_per, "0%")) as margin_per, 
        IF(((return_per = "NaN%") || (return_per = "-Infinity%") || (return_per = "Infinity%")), "0%", COALESCE(return_per, "0%"))  as return_per, 
        IF(((new_customer_per = "NaN%") || (new_customer_per = "-Infinity%") || (new_customer_per = "Infinity%")), "0%", COALESCE(new_customer_per, "0%"))  as new_customer_per, 
        IF(((repeat_customer_per = "NaN%") || (repeat_customer_per = "-Infinity%") || (repeat_customer_per = "Infinity%")), "0%", COALESCE(repeat_customer_per, "0%"))  as repeat_customer_per, 
        profit, current_total_price  from ?`, [final_res]);

    return jsonToArrayObject(final_res, ["Source", "Revenue", "AOV", "Margin %", "Return %", "New Customer %", "Repeat Customer %", "Profit", "Net Revenue"]);
}


function get_utm_medium_data(data, created_at_min, created_at_max){
    var res = alasql(`SELECT utm_medium, ROUND(SUM(total_price)) as total_price, ROUND(SUM(total_price)/COUNT(id)) as AOV, ROUND((SUM(profit)/SUM(total_price)) *100 )+ "%" as margin_per, ROUND(((SUM(total_price) - SUM(current_total_price))/SUM(total_price))*100) + "%" as return_per, ROUND((COUNT(DISTINCT case when (DATE(customer_joining_date) >= DATE('${created_at_min}') AND DATE(customer_joining_date) <= DATE('${created_at_max}')) then customer_id else null end) / COUNT(DISTINCT customer_id)) * 100) + "%" as new_customer_per, ROUND(SUM(profit)) as profit, ROUND(SUM(current_total_price)) as current_total_price  FROM ? GROUP BY utm_medium`,[data]);

    var repeat_cust_res = alasql(`SELECT customer_id, FIRST(utm_medium) as utm_medium, count(id) as no_of_order FROM ? WHERE (DATE(customer_joining_date) >= DATE('${created_at_min}') AND DATE(customer_joining_date) <= DATE('${created_at_max}')) GROUP BY customer_id`,[data]);
    repeat_cust_res = alasql(`SELECT utm_medium, ROUND((COUNT(case when no_of_order > 1 then customer_id else null end) / COUNT(customer_id)) * 100) + "%" as repeat_customer_per FROM ? GROUP BY utm_medium`,[repeat_cust_res]);

    var final_res = alasql(`SELECT * FROM ? AS a LEFT JOIN ? AS b ON a.utm_medium = b.utm_medium`, [res,repeat_cust_res]);
    final_res = alasql(`SELECT 
        IF(utm_medium = '', 'N/A', utm_medium) AS utm_medium, 
        total_price, 
        AOV, 
        IF(((margin_per = "NaN%") || (margin_per = "-Infinity%") || (margin_per = "Infinity%")), "0%", COALESCE(margin_per, "0%")) as margin_per, 
        IF(((return_per = "NaN%") || (return_per = "-Infinity%") || (return_per = "Infinity%")), "0%", COALESCE(return_per, "0%"))  as return_per, 
        IF(((new_customer_per = "NaN%") || (new_customer_per = "-Infinity%") || (new_customer_per = "Infinity%")), "0%", COALESCE(new_customer_per, "0%"))  as new_customer_per, 
        IF(((repeat_customer_per = "NaN%") || (repeat_customer_per = "-Infinity%") || (repeat_customer_per = "Infinity%")), "0%", COALESCE(repeat_customer_per, "0%"))  as repeat_customer_per, 
        profit, current_total_price from ?`, [final_res]);

    return jsonToArrayObject(final_res, ["Medium", "Revenue", "AOV", "Margin %", "Return %", "New Customer %", "Repeat Customer %", "Profit", "Net Revenue"]);
}


function get_utm_campaign_data(data, created_at_min, created_at_max){
    var res = alasql(`SELECT utm_campaign, ROUND(SUM(total_price)) as total_price, ROUND(SUM(total_price)/COUNT(id)) as AOV, ROUND((SUM(profit)/SUM(total_price)) *100 )+ "%" as margin_per, ROUND(((SUM(total_price) - SUM(current_total_price))/SUM(total_price))*100) + "%" as return_per, ROUND((COUNT(DISTINCT case when (DATE(customer_joining_date) >= DATE('${created_at_min}') AND DATE(customer_joining_date) <= DATE('${created_at_max}')) then customer_id else null end) / COUNT(DISTINCT customer_id)) * 100) + "%" as new_customer_per, ROUND(SUM(profit)) as profit, ROUND(SUM(current_total_price)) as current_total_price FROM ? GROUP BY utm_campaign`,[data]);

    var repeat_cust_res = alasql(`SELECT customer_id, FIRST(utm_campaign) as utm_campaign, count(id) as no_of_order FROM ? WHERE (DATE(customer_joining_date) >= DATE('${created_at_min}') AND DATE(customer_joining_date) <= DATE('${created_at_max}')) GROUP BY customer_id`,[data]);
    repeat_cust_res = alasql(`SELECT utm_campaign, ROUND((COUNT(case when no_of_order > 1 then customer_id else null end) / COUNT(customer_id)) * 100) + "%" as repeat_customer_per FROM ? GROUP BY utm_campaign`,[repeat_cust_res]);

    var final_res = alasql(`SELECT * FROM ? AS a LEFT JOIN ? AS b ON a.utm_campaign = b.utm_campaign`, [res,repeat_cust_res]);
    final_res = alasql(`SELECT 
        IF(utm_campaign = '', 'N/A', utm_campaign) AS utm_campaign,  
        total_price, 
        AOV, 
        IF(((margin_per = "NaN%") || (margin_per = "-Infinity%") || (margin_per = "Infinity%")), "0%", COALESCE(margin_per, "0%")) as margin_per, 
        IF(((return_per = "NaN%") || (return_per = "-Infinity%") || (return_per = "Infinity%")), "0%", COALESCE(return_per, "0%"))  as return_per, 
        IF(((new_customer_per = "NaN%") || (new_customer_per = "-Infinity%") || (new_customer_per = "Infinity%")), "0%", COALESCE(new_customer_per, "0%"))  as new_customer_per, 
        IF(((repeat_customer_per = "NaN%") || (repeat_customer_per = "-Infinity%") || (repeat_customer_per = "Infinity%")), "0%", COALESCE(repeat_customer_per, "0%"))  as repeat_customer_per, 
        profit, current_total_price from ?`, [final_res]);

    return jsonToArrayObject(final_res, ["Campaign", "Revenue", "AOV", "Margin %", "Return %", "New Customer %", "Repeat Customer %", "Profit", "Net Revenue"]);
}