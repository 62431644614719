import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { DateRangePicker } from "rsuite";
import alasql from "alasql";
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import FormSelect from 'react-bootstrap/FormSelect';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 

alasql.fn.getDateString = function(date_str){
    return date_str.split("T")[0];
}
alasql.fn.FLOAT = function(num_str){
    return parseFloat(num_str);
}

function MetricsPerformance({ViewedPage, shop_domain, app_url}) {
    ViewedPage(0)
    
    const [loading, setLoading] = useState(true);
    const [utm_source_select_dict, setUTMSource] = useState([]);
    const [utm_medium_select_dict, setUTMMedium] = useState([]);
    const [selected_date_range_value, setDateRange] = useState([]);
    const [order_raw_data, setOrderRawData] = useState([]);
    const [order_net_revenue_data, setOrderNetRevenueData] = useState([]);
    const [order_revenue_data, setOrderRevenueData] = useState([]);
    const [aov_order_data, setAOVOrderValue] = useState([]);
    const [new_customer, setNewCustomer] = useState([]);
    const [order_profit_data, setOrderProfitValue] = useState([]);
    const line_charts_config = {
        chartArea: {'width': '80%', 'height': '70%', 'top':'10%'},
        titleTextStyle : {fontSize: 16},
        hAxis: { 
            format: 'MMM d, y',
            gridlines: {
                color: 'transparent'
            },
        },
        vAxis: {
            format: "short",
            gridlines: {
                color: '#edf1f4',
            },
        },
        explorer: {
            axis: "horizontal",
            //   actions: ["dragToZoom", "rightClickToReset"],
            keepInBounds: true,
            maxZoomIn: 4,
            maxZoomOut: 1,
        },
        legend: "none",
        series: [{'color': '#00b6ef', 'areaOpacity': 0.1}],
        animation: { startup: true, easing: "linear", duration: 1000 },
    };
    
    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
                setDateRange([created_at_min, created_at_max]);
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    setOrderRawData(formated_data);

                    var { utm_source_list, utm_medium_list } = get_utm_paramters_list(formated_data);
                    setUTMSource(utm_source_list);
                    setUTMMedium(utm_medium_list);

                    setOrderRevenueData(get_order_revenue_data(formated_data));
                    setOrderNetRevenueData(get_order_net_revenue_data(formated_data));
                    setAOVOrderValue(get_high_aov_order_data(formated_data));
                    setOrderProfitValue(get_order_profit_data(formated_data));
                    setNewCustomer(get_new_customer(formated_data, formatDate(created_at_min), formatDate(created_at_max)));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });                
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }, [app_url, shop_domain]);


    const handleDateChange = async (date_range) => {
        setDateRange(date_range);
        var created_at_max = new Date();
        var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
        if(date_range.length > 0){
            created_at_min = date_range[0];
            created_at_max = date_range[1];
        }
        var utm_source = document.getElementById("utm_source").value;
        var utm_medium = document.getElementById("utm_medium").value;
        setLoading(true);

        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    setOrderRawData(formated_data);

                    var { utm_source_list, utm_medium_list } = get_utm_paramters_list(formated_data);
                    setUTMSource(utm_source_list);
                    setUTMMedium(utm_medium_list);

                    setNewCustomer(get_new_customer(formated_data, formatDate(created_at_min), formatDate(created_at_max), utm_source, utm_medium));
                    formated_data = filter_data(formated_data, utm_source, utm_medium);
                    setOrderRevenueData(get_order_revenue_data(formated_data));
                    setOrderNetRevenueData(get_order_net_revenue_data(formated_data));
                    setAOVOrderValue(get_high_aov_order_data(formated_data));
                    setOrderProfitValue(get_order_profit_data(formated_data));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }

    }
    
    const handleFilters = async (e) => {
        e.preventDefault();
        var utm_medium = document.getElementById("utm_medium").value;
        var utm_source = document.getElementById("utm_source").value;
        setLoading(true);
        try{
            var formated_data = filter_data(order_raw_data, utm_source, utm_medium);
            setOrderRevenueData(get_order_revenue_data(formated_data));
            setOrderNetRevenueData(get_order_net_revenue_data(formated_data));
            setAOVOrderValue(get_high_aov_order_data(formated_data));
            setOrderProfitValue(get_order_profit_data(formated_data));
            setNewCustomer(get_new_customer(order_raw_data, formatDate(selected_date_range_value[0]), formatDate(selected_date_range_value[1]), utm_source, utm_medium));
            setLoading(false);
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }



    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Metrics Trend</h2>
                            </div>


                            <Container className='mt-3'>
                                <Row className='justify-content-md-left' lg={3} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker id='date_range' defaultValue={selected_date_range_value} format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">UTM Source</Label>
                                        <br />
                                        <FormSelect defaultValue={"all"} id='utm_source' onChange={handleFilters}>
                                            { utm_source_select_dict.map((source_iter) => <option key={source_iter["value"]} value={source_iter["value"]}>{source_iter["label"]}</option>)}
                                        </FormSelect>                                        
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">UTM Medium</Label>
                                        <br />
                                        <FormSelect defaultValue={"all"} id='utm_medium' onChange={handleFilters}>
                                            { utm_medium_select_dict.map((medium_iter) => <option key={medium_iter["value"]} value={medium_iter["value"]}>{medium_iter["label"]}</option>)}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Container>


                            <Container>
                                <Row className='justify-content-md-left mt-3' sm={2} xs={1}>
                                    <Col  className='p-2'>
                                        <p className='metric-performance-charts-title'>Revenue</p>
                                        <Chart
                                            chartType="AreaChart"
                                            width={"100%"}
                                            height={"300px"}
                                            data={order_revenue_data}
                                            options={line_charts_config}
                                        />
                                    </Col>
                                    <Col  className='p-2'>
                                        <p className='metric-performance-charts-title'>Net Revenue</p>
                                        <Chart
                                            chartType="AreaChart"
                                            width={"100%"}
                                            height={"300px"}
                                            data={order_net_revenue_data}
                                            options={line_charts_config}
                                        />
                                    </Col>
                                    <Col  className='p-2'>
                                        <p className='metric-performance-charts-title'>Average AOV</p>
                                        <Chart
                                            chartType="AreaChart"
                                            width={"100%"}
                                            height={"300px"}
                                            data={aov_order_data}
                                            options={line_charts_config}
                                        />
                                    </Col>
                                    <Col  className='p-2'>
                                        <p className='metric-performance-charts-title'>Gross Profit</p>
                                        <Chart
                                            chartType="AreaChart"
                                            width={"100%"}
                                            height={"300px"}
                                            data={order_profit_data}
                                            options={line_charts_config}
                                        />
                                    </Col>
                                    <Col  className='p-2'>
                                        <p className='metric-performance-charts-title'>New Customers</p>
                                        <Chart
                                            chartType="AreaChart"
                                            width={"100%"}
                                            height={"300px"}
                                            data={new_customer}
                                            options={line_charts_config}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default MetricsPerformance


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
  }


function jsonToArrayObject(res, graph_columns) {
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      for (let column_name of columns) {
        res_arr.push(obj[column_name]);
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
   return resultArray;
}


function formate_shopify_data(data){
    var res = alasql('SELECT country, created_at, getDateString(created_at) as Date, currency_code, CAST(current_total_price AS FLOAT) as current_total_price, customer_id, customer_joining_date, customer_tags, discount_code, discount_codes, id, last_visit_campaign as utm_campaign, last_visit_content as utm_content, last_visit_medium as utm_medium, last_visit_source as utm_source, last_visit_term as utm_term, product_title, product_type, profit, sku, tags, CAST(total_price AS FLOAT) as total_price FROM ?',[data]);

    return res;
}


function get_utm_paramters_list(data){
    var utm_medium_raw_list = alasql('SELECT DISTINCT utm_medium FROM ?',[data]);
    var utm_source_raw_list = alasql('SELECT DISTINCT utm_source FROM ?',[data]);
    
    var final_utm_source_list = [{"label":"All", "value":"all"}];
    var final_utm_medium_list = [{"label":"All", "value":"all"}];

    for(var source_iter of utm_source_raw_list){
        if(source_iter["utm_source"] !== ""){
            final_utm_source_list.push({
                "label": source_iter["utm_source"],
                "value": source_iter["utm_source"]
            });
        }
    }
    
    for(var medium_iter of utm_medium_raw_list){
        if(medium_iter["utm_medium"] !== ""){
            final_utm_medium_list.push({
                "label": medium_iter["utm_medium"],
                "value": medium_iter["utm_medium"]
            });
        }
    }


    return { 
        utm_source_list:final_utm_source_list,
        utm_medium_list:final_utm_medium_list
    };
}


function filter_data(data, utm_source, utm_medium){
    if((data.length > 0) && (utm_source !== "all") && (utm_source !== null) && (utm_source !== undefined)){
        data = alasql(`SELECT * FROM ? WHERE utm_source = "${utm_source}"`,[data]);
    }
    if((data.length > 0) && (utm_medium !== "all") && (utm_medium !== null) && (utm_medium !== undefined)){
        data = alasql(`SELECT * FROM ? WHERE utm_medium = "${utm_medium}"`,[data]);
    }
    return data;
}


function get_order_revenue_data(data){
    var res = alasql('SELECT DATE(Date) as Date, SUM(total_price) AS Revenue FROM ? GROUP BY Date ORDER BY Date',[data]);
    return jsonToArrayObject(res, [{"type":"date","label":"Date"}, "Revenue"]);
}


function get_order_profit_data(data){
    var res = alasql('SELECT DATE(Date) as Date, SUM(profit) AS Profit FROM ? GROUP BY Date ORDER BY Date',[data]);
    return jsonToArrayObject(res, [{"type":"date","label":"Date"}, "Gross Profit"]);
}


function get_new_customer(data, created_at_min, created_at_max, utm_source=null, utm_medium=null){
    try{
        var res = alasql(`SELECT customer_id, getDateString(FIRST(customer_joining_date)) AS joing_date, FIRST(utm_source) as utm_source, FIRST(utm_medium) as utm_medium FROM ? WHERE (DATE(getDateString(customer_joining_date)) >= DATE('${created_at_min}') AND DATE(getDateString(customer_joining_date)) <= DATE('${created_at_max}')) GROUP BY customer_id`,[data]);
        console.log(created_at_min, created_at_max)
        console.log(res)
        if((utm_source !== null) && (utm_source !== "all")){
            res = alasql(`SELECT * FROM ? WHERE utm_source = "${utm_source}"`,[res]);
            console.log(res)
        }
        if((utm_medium !== null) && (utm_medium !== "all")){
            res = alasql(`SELECT * FROM ? WHERE utm_medium = "${utm_medium}"`,[res]);
            console.log(res)
        }
        res = alasql('SELECT DATE(joing_date) as Date, COUNT(customer_id) as [New Customer] FROM ? GROUP BY joing_date ORDER BY Date',[res]);
        return jsonToArrayObject(res, [{"type":"date","label":"Date"}, "New Customers"]);
    }
    catch(err){
        console.log(err)
        return [[{"type":"date","label":"Date"}, "New Customers"]];
    }
}

function get_order_net_revenue_data(data){
    var res = alasql('SELECT DATE(Date) as Date, SUM(current_total_price) AS Revenue FROM ? GROUP BY Date ORDER BY Date',[data]);
    return jsonToArrayObject(res, [{"type":"date","label":"Date"}, "Net Revenue"]);
}


function get_high_aov_order_data(data){
    var res = alasql('SELECT DATE(Date) as Date, (SUM(total_price)/COUNT(id)) AS average_aov FROM ? GROUP BY Date ORDER BY Date',[data]);
    return jsonToArrayObject(res, [{"type":"date","label":"Date"}, "Average AOV"]);
}