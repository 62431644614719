import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Modal, Card } from 'react-bootstrap';
import Label from 'react-bootstrap/FormLabel';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import '../ChatApp.css';
import send_icon from '../images/send.png';
import settings_icon from '../images/settings_icon.png';
import Spinner from 'react-bootstrap/Spinner';
import logo from '../images/scalex_ai_logo.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import alasql from 'alasql';
import toast from 'react-hot-toast';

function ChatApp({ViewedPage, shop_domain, app_url}) {
    ViewedPage(20);

    const messagesEndRef = useRef(null);

    const [show, setShow] = useState(false);
    const target = useRef(null);

    
    const [loading, setLoading] = useState(true);
    const [submit_btn_loading, setSubmitBtnLoading] = useState(false);
    const [show_settings, setShowSettings] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [is_analyse, setIsAnalyse] = useState(true);
    const [all_creative_data, setCreativeData] = useState([]);
    const [all_insights_data, setInsightsData] = useState([]);
    const [all_dropdown_options, setDropdownOptions] = useState([]);
    const [selected_channel, setSelectedChannel] = useState({value:"None",label:"None"});
    const [selected_dropdown_1, setDropdown1] = useState({value:"None",label:"None"});
    const [selected_dropdown_2, setDropdown2] = useState([]);
    const [meta_data, setMetaData] = useState([]);
    const [extra_prompt, setExtraPrompt] = useState("");

    useEffect(() => {
        setLoading(true);
        try{
            setMessages(JSON.parse(((sessionStorage.getItem(shop_domain.replace('.myshopify.com','')+'_messages')) && (sessionStorage.getItem(shop_domain.replace('.myshopify.com','')+'_messages') !== "undefined") && (sessionStorage.getItem(shop_domain.replace('.myshopify.com','')+'_messages') !== "null")) ? sessionStorage.getItem(shop_domain.replace('.myshopify.com','')+'_messages') : '[]'));
            if (messagesEndRef.current) {
              // Scroll to the bottom of the div
              messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
            }
            var created_at_max = new Date();
            var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
            axios.post(app_url+"/api/facebook/get_meta_creatives_data_from_redis",{
                shop_domain:Cookies.get('shop'),
                date_start:formatDate(created_at_min),
                date_end:formatDate(created_at_max)
            }, {withCredentials:true})
            .then(res => res.data)
            .then(data => {
                console.log(data)
                setCreativeData(data["data"]["creative_data"]);
                setInsightsData(data["data"]["insights_data"]);
                setDropdownOptions(get_dropdown_options(data["data"]["insights_data"]))
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                toast.error("Please try after some time.");
                console.log(err);
            })
        }
        catch(error){
            console.error("Error:",error);
            toast.error("Please try after some time.");
            setLoading(false);
        }
    },[app_url, shop_domain])

    useEffect(() => {
        if (messagesEndRef.current) {
          // Scroll to the bottom of the div
          messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [messages]);
    
    const handle_settings_hide = () => setShowSettings(false);
    const handle_settings_show = () => setShowSettings(true);
    const handle_settings_submit = (e) => {
        let temp_meta_data = [];
        let temp_extra_prompt = "";
        setIsAnalyse(true);
        if(selected_channel.value === "google"){
            console.log("Google")
        }
        else if(selected_channel.value === "facebook"){
            console.log("Facebook")
            temp_meta_data = get_meta_analysis_data(all_insights_data, all_creative_data, selected_dropdown_1, selected_dropdown_2);
            setMetaData(temp_meta_data)
            temp_extra_prompt = ["Below are the top performing copies:\n"+temp_meta_data.map((val) => {return "Title:'"+val["title"]+"', Primary Text:'"+val["descriptor"]+"'"}).join("\n ") , "\nCategorize these top performing copies into communication themes.\nSuggest 5 more message themes that can be used with examples"];
            setExtraPrompt(temp_extra_prompt);
        }

        if (temp_meta_data.length > 0) {
            let input = { 
                role: 'user', 
                content: `
Analyse data for the following: 
    - Channel: ${selected_channel["label"]} 
    ${selected_dropdown_1["label"] === "None" ? "" : "- "+ selected_dropdown_1["label"] + " in ('" + selected_dropdown_2.map((val) => { return val["label"] }).join("', '") + "')"}`.trim(), 
                exclude:true, 
                card:false
            };
            setSubmitBtnLoading(true)
            try{
                axios.post(app_url+"/chats",{
                    new_message:temp_extra_prompt[0]+"\n"+temp_extra_prompt[1], 
                    old_message: [],
                    format:"text", 
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    // Simulate AI response (this should be replaced with actual API call)
                    const analysis_data = { role: 'assistant', content: temp_meta_data, exclude:true, card:true };
                    const botReply = { role: 'assistant', content: data["data"], exclude:true, card:false };
                    sessionStorage.setItem(shop_domain.replace('.myshopify.com','')+'_messages',JSON.stringify([...messages, input, analysis_data, botReply]));
                    setMessages((prev) => [...prev, input, analysis_data, botReply]);
                    setSubmitBtnLoading(false)
                })
                .catch(err => {
                    setSubmitBtnLoading(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
            catch(error){
                toast.error("Please try after some time.");
                console.log(error);
            }
        }
        else{
            toast.success("No Data to analyse.");
        }
        handle_settings_hide();
    }



    // Handles form submission when the user sends a message
    const handleSubmit = (e) => {
        e.preventDefault();
        if (input.trim()) {
            const newMessage = { role: 'user', content: input };
            sessionStorage.setItem(shop_domain.replace('.myshopify.com','')+'_messages',JSON.stringify([...messages, newMessage]));
            setMessages([...messages, newMessage]);
            setInput(''); // clear the input field after sending
            
            setSubmitBtnLoading(true);
            try{
                let message_with_removed_excludes = messages.filter((val) => { return ((!Object.keys(val).includes("exclude")) || (val["exclude"] === false))});
                let old_message = message_with_removed_excludes.length >= 2 ? message_with_removed_excludes.slice(-2) : [];
                console.log("Old Message",old_message)
                axios.post(app_url+"/chats",{
                    new_message:input, 
                    old_message: old_message,
                    format:"text", 
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    // Simulate AI response (this should be replaced with actual API call)
                    const botReply = { role: 'assistant', content: data["data"] };
                    sessionStorage.setItem(shop_domain.replace('.myshopify.com','')+'_messages',JSON.stringify([...messages, newMessage, botReply]));
                    setMessages((prev) => [...prev, botReply]);
                    setSubmitBtnLoading(false)
                })
                .catch(err => {
                    setSubmitBtnLoading(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
            catch(error){
                toast.error("Please try after some time.");
                console.log(error);
            }
        }
    };

    return (
        <div className='main-container chatbot' style={{position:"relative"}}>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Contextual Ideation</h2>
                            </div>

                            { show_settings &&
                                    <div style={{backgroundColor:"#132848", width:"220px", padding:"0.5rem", position:"fixed", top:"0", bottom:"0", right:"0"}} >
                                        <Container className='mt-4'>
                                            <Row className='justify-content-md-left' lg={1} xs={1}>
                                                <Col className='p-2 mt-2'>
                                                    <Label className="communication_ai_label">Channel</Label>
                                                    <br />
                                                    <ReMultiSelect 
                                                        id="channel_value"
                                                        closeMenuOnSelect={true}
                                                        components={ReMultiSelectAnimated()}
                                                        defaultValue={selected_channel}
                                                        options={[
                                                            {"value":"None","label": "None"},
                                                            {"value":"facebook","label": "Facebook"},
                                                        ]}
                                                        onChange={(selected_vals) => {
                                                            setSelectedChannel(selected_vals)
                                                        }}
                                                    />
                                                </Col>
                                                <Col className='p-2 mt-2'>
                                                    <Label className="communication_ai_label">Category</Label>
                                                    <br />
                                                    <ReMultiSelect 
                                                        id="scattered_tags_value"
                                                        closeMenuOnSelect={true}
                                                        components={ReMultiSelectAnimated()}
                                                        defaultValue={selected_dropdown_1}
                                                        options={[
                                                            {"value":"None","label": "None"},
                                                            {"value":"tactics","label": "Tactics"},
                                                            {"value":"campaign_name","label": "Campaign Name"},
                                                            {"value":"adset_name","label": "Adset Name"},
                                                        ]}
                                                        onChange={(selected_vals) => {
                                                            setDropdown1(selected_vals)
                                                        }}
                                                    />
                                                </Col>
                                                <Col className='p-2 mt-2'>
                                                    <Label className="communication_ai_label">Subcategory</Label>
                                                    <br />
                                                    <ReMultiSelect 
                                                        style={{height:"300px"}}
                                                        id="scattered_tags_value"
                                                        closeMenuOnSelect={true}
                                                        components={ReMultiSelectAnimated()}
                                                        isMulti
                                                        defaultValue={selected_dropdown_2}
                                                        options={all_dropdown_options[selected_dropdown_1["value"]]}
                                                        onChange={(selected_vals) => {
                                                            setDropdown2(selected_vals)
                                                        }}
                                                    />
                                                </Col>
                                                <Col className='p-2 d-flex justify-content-between mt-4'>
                                                    <Button style={{ width:"48%", backgroundColor:"white", border:"none", color:"black"}} onClick={handle_settings_submit}><b>Analyse</b></Button>
                                                    <Button style={{width:"48%", backgroundColor:"white", border:"none", color:"black"}} onClick={handle_settings_hide}><b>Close</b></Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                }

                            <Container className="chat-container dark-theme">
                                <Row>
                                    <Col className="chat-window">
                                        <div ref={messagesEndRef} className="chat-history">
                                            {messages.map((msg, index) => (
                                                <div key={index} className={`message ${msg.role === 'user' ? 'message-bar-right' : 'message-bar-left'}`}>
                                                    {msg.role === 'user' ?
                                                        <pre className='user-message'>
                                                            {msg.content}
                                                        </pre>
                                                    :
                                                        <>
                                                            <img src={logo} alt="AI" width={25} height={25} style={{display: 'inline-block', margin:"5px", marginRight: '15px', borderRadius: '10rem'}} />
                                                            { (Object.keys(msg).includes("card")) && (msg["card"] === true) ?
                                                                <>
                                                                    { msg.content.length > 0 ?
                                                                        <div className='ai-message max-w-95' style={{backgroundColor: 'transparent'}}>
                                                                            <div className='d-flex flex-row' style={{overflow: 'scroll'}}>
                                                                                {msg.content.map((data, index) => {
                                                                                    let temp_shop_domain = shop_domain.replace(".myshopify.com", "");
                                                                                    let creative_url = `https://storage.googleapis.com/scalex-ai/scaleX_AI_${temp_shop_domain}/Meta_Creative/${data["creative_id"]}`;
                                                                                    return (
                                                                                        <Col className='p-2' style={{minWidth: '250px'}}>
                                                                                            <on_hover_tooltip title={`
Ad Name : ${data["ad_name"]}
Title : ${data["title"]}
Primary Text : ${data["descriptor"]}
Spend : ${data["spend"].toFixed(1)}
Revenue : ${data["purchase_value"].toFixed(1)}
Impressions : ${data["impressions"]}
Clicks : ${data["clicks"]}
`}>
                                                                                                <Card className='creative-analysis-cards' key={index}>                                                
                                                                                                    <Card.Img variant="top" height={200} src={creative_url} />
                                                                                                    <Card.Body>
                                                                                                        <Card.Text as={'div'}>
                                                                                                        <p className='mb-1 font-size-smaller'>Title : {data["title"]}</p>
                                                                                                        {/* <p className='mb-1'>Spend : {data["spend"]}</p>
                                                                                                        <p className='mb-1'>Revenue : {data["purchase_value"]}</p>
                                                                                                        <p className='mb-1'>Impressions : {data["impressions"]}</p>
                                                                                                        <p className='mb-1'>Clicks : {data["clicks"]}</p> */}
                                                                                                        </Card.Text>
                                                                                                    </Card.Body>
                                                                                                </Card>
                                                                                            </on_hover_tooltip>
                                                                                        </Col>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                    <pre className='ai-message'>No Data for this filter.</pre>
                                                                    }
                                                                </>
                                                                :
                                                                <pre className='ai-message'>
                                                                    {msg.content.replaceAll("#","").replaceAll("*","")}
                                                                </pre>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                            <div className="input-form-main-div">
                                <Form className='input-form' onSubmit={handleSubmit}>
                                    <div className="input-area">
                                        <input
                                            type="text"
                                            placeholder="Message Communication AI"
                                            value={input}
                                            onChange={(e) => setInput(e.target.value)}
                                        />
                                        <div>
                                            { submit_btn_loading?
                                                <button>
                                                    <div className='main_conatiner_spinner'>
                                                        <Spinner animation="border"/>
                                                    </div>
                                                </button>
                                            :
                                                <>
                                                    <button type="submit">
                                                        <img src={send_icon} alt='Send' width={25} height={25}/>
                                                    </button>
                                                    <button style={{backgroundColor:"white",color:"#00d96d",border:"none",fontWeight:'500'}} onClick={(e) => {
                                                        handle_settings_show();
                                                    }}>
                                                        <img src={settings_icon} alt='Settings' width={20} height={20}/>
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </>
                    : 
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }

                </>
            }
        </div>
    )

}

export default ChatApp;


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}

function get_dropdown_options(insights_data){
    let dropdown_options = {
        "None":[],
        "tactics":[
            {value:"Remarketing",label:"Remarketing"},
            {value:"Prospecting",label:"Prospecting"},
        ],
        "campaign_name":[],
        "adset_name":[],
    };
    let campaign_name_arr = alasql(`SELECT ARRAY(DISTINCT campaign_name) AS campaign_name FROM ?`,[insights_data])[0]["campaign_name"];
    campaign_name_arr.forEach((val) => {
        dropdown_options["campaign_name"].push({value:val,label:val})
    })
    let adset_name_arr = alasql(`SELECT ARRAY(DISTINCT adset_name) AS adset_name FROM ?`,[insights_data])[0]["adset_name"];
    adset_name_arr.forEach((val) => {
        dropdown_options["adset_name"].push({value:val,label:val})
    })
    return dropdown_options
}


function get_meta_analysis_data(insight_data, creative_data, selected_dropdown_1, selected_dropdown_2){
    let temp_insights_data = alasql("SELECT ad_name, FIRST(campaign_id) AS campaign_id, FIRST(adset_id) AS adset_id, FIRST(campaign_name) AS campaign_name, FIRST(adset_name) AS adset_name, FIRST(ad_id) AS ad_id, SUM(CAST(spend AS FLOAT)) AS spend, SUM(CAST(purchase_value AS FLOAT)) AS purchase_value, SUM(CAST(impressions AS FLOAT)) AS impressions, SUM(CAST(clicks AS FLOAT)) AS clicks FROM ? GROUP BY ad_name", [insight_data]);
    let filtered_data = alasql("SELECT * FROM ? AS arr1 LEFT JOIN ? AS arr2 ON arr1.ad_id = arr2.ad_id ", [creative_data, temp_insights_data]);
    filtered_data = alasql("SELECT * FROM ? ORDER BY ad_name, spend", [filtered_data]);
    // filtered_data = alasql("SELECT ad_name, FIRST(campaign_id) AS campaign_id, FIRST(adset_id) AS adset_id, FIRST(campaign_name) AS campaign_name, FIRST(adset_name) AS adset_name, FIRST(ad_id) AS ad_id, FIRST(creative_id) AS creative_id, FIRST(title) AS title, FIRST(descriptor) AS descriptor, SUM(CAST(spend AS FLOAT)) AS spend, SUM(CAST(purchase_value AS FLOAT)) AS purchase_value, SUM(CAST(impressions AS FLOAT)) AS impressions, SUM(CAST(clicks AS FLOAT)) AS clicks FROM ? GROUP BY ad_name", [filtered_data]);
    if(selected_dropdown_1.value === "None"){
        filtered_data = alasql(`SELECT * FROM ? ORDER BY COALESCE(CAST(spend AS FLOAT), 0) DESC LIMIT 4`,[filtered_data]);
    }
    else if(selected_dropdown_1.value === "tactics"){
        filtered_data = alasql(`SELECT * FROM ? WHERE ((campaign_name LIKE '%${selected_dropdown_2[0].value.replaceAll("'","''")}%')) ORDER BY COALESCE(CAST(spend AS FLOAT), 0) DESC LIMIT 4`,[filtered_data]);
    }
    else if(selected_dropdown_1.value === "campaign_name"){
        filtered_data = alasql(`SELECT * FROM ? WHERE ((campaign_name IN ('${selected_dropdown_2.map((val) => val.value.replaceAll("'","''")).join("','")}'))) ORDER BY COALESCE(CAST(spend AS FLOAT), 0) DESC LIMIT 4`,[filtered_data]);
    }
    else if(selected_dropdown_1.value === "adset_name"){
        filtered_data = alasql(`SELECT * FROM ? WHERE ((adset_name IN ('${selected_dropdown_2.map((val) => val.value.replaceAll("'","''")).join("','")}'))) ORDER BY COALESCE(CAST(spend AS FLOAT), 0) DESC LIMIT 4`,[filtered_data]);
    }
    console.log(filtered_data)
    return filtered_data;
}